import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../utils/store";
import { AxiosError } from "axios";
import { notify } from "./notification";
import routesService from "../services/routes";
import { NotificationLevel } from "../types/Notification";

const initialstate = [] as string[];

const routesSlice = createSlice({
    name: "routes",
    initialState: initialstate,
    reducers: {
        setRoutes(state, action: PayloadAction<string[]>): string[] {
            return action.payload;
        },
    },
})

const { setRoutes } = routesSlice.actions;

export const initializeRoutes = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const routes = await routesService.getRoutes();
            dispatch(setRoutes(routes));
        } catch (error) {
            if (error instanceof AxiosError) {
                dispatch(
                    notify({
                        message: `Failed to fetch routes`,
                        level: NotificationLevel.ERROR,
                    })
                );
            }
        }

    }
}


export default routesSlice.reducer