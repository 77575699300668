import { PlusIcon } from "../../../svg/icons";
import { Box, Typography } from "@mui/material";
import { SetStateAction } from "react";
import styles from "./stagesSwitcher.module.scss";

interface Stage {
  id: number;
  title: string;
  order: number;
  // candidates: Candidate[]; // Update candidates type to match the actual type
}
export interface Props {
  setActiveStage?: any;
  activeStage?: Stage | undefined;
  uniqueStages?: Stage[];
  isLeads?: boolean;
  setIsLeads?: (value: SetStateAction<boolean>) => void;
}

const StagesSwitcher: React.FC<Props> = ({
  setActiveStage,
  setIsLeads,
  activeStage,
  uniqueStages,
  isLeads,
}) => {
  return (
    <Box className={`${styles.leadsCond} ${isLeads ? "hidden" : "flex"}`}>
      <Box className={styles.stageNameBox}>
        <Typography className={styles.stageName}>STAGE NAME</Typography>
      </Box>
      <Box className={styles.names}>
        {uniqueStages?.map((stage: Stage, index: number) => {
          const isActiveStage = activeStage && activeStage.id === stage.id;

          return (
            <Box
              key={index}
              className={`${styles.stageNames} ${
                isActiveStage ? styles.active : ""
              }`}
              onClick={() => {
                setActiveStage && setActiveStage(stage);
                console.log("selectedstage", stage);
                setIsLeads && setIsLeads(false);
              }}
            >
              <PlusIcon className={styles.plusIcon} />
              <Typography sx={{ color: "#000" }}>{stage.title}</Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default StagesSwitcher;
