import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../utils/store";
import { notify } from "./notification";
import { NotificationLevel } from "../types/Notification";
import { AxiosError } from "axios";
import permissionService from "../services/permissions";
import { Permission } from "../types/Role";

const initialState = [] as Permission[];

const permissionsSlice = createSlice({
  name: "Permissions",
  initialState,
  reducers: {
    setPermissions(state, action: PayloadAction<Permission[]>) {
      return action.payload;
    },
  },
});

export const { setPermissions } = permissionsSlice.actions;

export const initializePermissions = () => async (dispatch: AppDispatch) => {
  try {
    const permissions = await permissionService.getPermissions();
    dispatch(setPermissions(permissions));
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      dispatch(
        notify({
          message: `Failed to fetch Permissions`,
          level: NotificationLevel.ERROR,
        })
      );
    }
  }
};

export default permissionsSlice.reducer;
