import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import MaterialReactTable, {
  type MRT_ColumnDef,
  type MRT_Row,
} from "material-react-table";
import Papa from "papaparse";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Anchor, ContentCopy, UploadFile } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { ExportToCsv } from "export-to-csv";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout";
import { AppDispatch, RootState } from "../../utils/store";
import ColumnsEdit from "../../components/ColumnsEdit";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  deleteManyCandidates,
  initializeCandidates,
  updateManyCandidates,
  updateOneCandidate,
} from "../../reducers/candidates";
import {
  Box,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  FormControlLabel,
} from "@mui/material";
import Select from "../../components/UI/Select";
import Button from "../../components/UI/Button";
import Modal from "../../components/UI/Modal";
import Candidate from "../../types/Candidate";
import Table from "../../components/elements/Table";
import TextField from "../../components/UI/TextField";
import CheckBox from "../../components/UI/CheckBox";

import { createCandidates } from "../../reducers/candidates";
import AddCandidatesForm from "../../components/AddCandidatesForm";
import CandidateProfile from "../../components/CandidateProfile";
import { initializeCompanies } from "../../reducers/companies";
import { UserRole } from "../../types/User";
import "../../styles/quill-snow.css";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import ActionsBar from "../../components/elements/ActionsBar";
import styles from "./Modal.module.scss";
import Drawer from "../../components/UI/Drawer";

const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;

const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: [] }, { background: [] }, "link", "image"],
    [{ align: [] }, { direction: "rtl" }, { indent: "-1" }, { indent: "+1" }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
};

const quilFormats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "code-block",
  "link",
  "image",
  "align",
  "indent",
  "list",
  "bullet",
  "color",
  "background",
  "direction",
];

export function ChildModal({
  subject,
  message,
  setSubject,
  setMessage,
  language,
  setLanguage,
}: {
  subject: string;
  message: string;
  language: string;
  setSubject: (value: string) => void;
  setLanguage: any;
  setMessage: (value: string) => void;
}) {
  const [t] = useTranslation(["common"]);
  const style = {
    position: "absolute" as "absolute",
    top: "2.5%",
    left: "10%",
    width: "80%",
    height: "95%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: "auto",
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen}>{t("writeEmail")}</Button>
      <Modal
        isOpen={open}
        onCancel={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box component="form" className={styles.modalContent} noValidate>
          <Typography variant="h4" textAlign="center" sx={{ mb: 1 }}>
            {t("writeEmail")}
          </Typography>
          <TextField
            required
            fullWidth
            label={t("subject")}
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.currentTarget.value)}
          />
          <Select
            placeholder="Select language"
            options={[
              { label: "EN", value: "EN" },
              { label: "CZ", value: "CZ" },
            ]}
            onChange={(event) => setLanguage(event.value as any)}
          />
          <ReactQuill
            value={message}
            onChange={(value: string) => setMessage(value)}
            modules={quillModules}
            formats={quilFormats}
          />
          <Box className={styles.buttons}>
            <Button
              type="submit"
              color="success"
              variant="contained"
              onClick={() => {
                handleClose();
              }}
            >
              {t("save")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                setSubject("");
                setMessage("");
                handleClose();
              }}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default function Candidates() {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [rowSelection, setRowSelection] = useState({});

  const [t] = useTranslation(["common"]);
  const [language, setLanguage] = useState<"EN" | "CZ">("EN");
  const ref = useRef<HTMLInputElement>(null);
  const { user, candidates, companies } = useSelector((state: RootState) => ({
    user: state.user,
    candidates: state.candidates,
    companies: state.companies,
  }));
  const [candidatesToAssign, setCandidatesToAssign] = useState<Candidate[]>([]);
  const [candidatesToAssignToPage, setCandidatesToAssignToPage] = useState<
    Candidate[]
  >([]);
  const [filteredCompanies, setFilteredCompanies] = useState<string[]>([]);
  const [filteredJobs, setFiliteredJobs] = useState<string[]>([]);
  const [filteredStages, setFiliteredStages] = useState<string[]>([]);
  const [ShowColumnsEditModal, setShowColumnsEditModal] = useState(false);
  const [showCandidateProfile, setShowCandidateProfile] = useState(false);
  const [SelectedColumnsToShow, setSelectedColumnsToShow] = useState<string[]>([
    "ID",
    "Actions",
    "Profile",
    "First Name",
    "Last Name",
    "Email",
    "Linkedin URL",
    "Stage",
    "CV File",
  ]);
  const [checkedColumns, setCheckedColumns] = useState({
    ID: true,
    Actions: true,
    "First Name": true,
    "Last Name": true,
    Email: true,
    "Linkedin URL": true,
    Stage: true,
    "CV File": true,
  });
  const [selectedCompany, setSelectedCompany] = useState<{
    label: string;
    value: number;
  }>();
  const [selectedJob, setSelectedJob] = useState<{
    label: string;
    value: number;
  }>();
  const [selectedPage, setSelectedPage] = useState<{
    label: string;
    value: string;
  }>();

  const [openEditCandidateMdoel, setOpenEditCandidateMdoel] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate>();
  const [customColumns, setCustomColumns] = useState<
    Record<string, { id: number; value: string }>
  >({});
  const [selectedCandidateId, setSelectedCandidateId] = useState<number>();

  const handleOpenEditCandidateModal = () => setOpenEditCandidateMdoel(true);

  const handleCloseEditCandidateModal = () => setOpenEditCandidateMdoel(false);

  const filteredByCompaniesCandidates =
    filteredCompanies.length > 0
      ? candidates.filter((candidate) =>
          filteredCompanies.some(
            (companyName) =>
              companyName ===
              companies.find(({ id }) => candidate.companyId === id)?.name
          )
        )
      : candidates;

  const filiteredByJobsCandidates =
    filteredJobs.length > 0
      ? filteredByCompaniesCandidates.filter((candidate) =>
          filteredJobs.some((job) => job === candidate.stage?.job.title)
        )
      : filteredByCompaniesCandidates;

  const filiteredByStagesCandidates =
    filteredStages.length > 0
      ? filteredByCompaniesCandidates.filter((candidate) =>
          filteredStages.some(
            (stageTitle) =>
              stageTitle === candidate.stage?.title &&
              filteredJobs.some((job) => job === candidate.stage?.job.title)
          )
        )
      : filiteredByJobsCandidates;

  const [newCandidates, setNewCandidates] = useState<
    { [key: string]: string }[] | null
  >(null);
  const [subject, setSubject] = useState<string>("A new recommended candidate");
  const [message, setMessage] = useState<string>(
    "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>"
  );
  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  interface TableColumn {
    accessorKey?: string;
    header: string;
    size?: number;
    Cell?: React.ComponentType<any>;
    enableClickToCopy?: boolean;
    muiTableBodyCellCopyButtonProps?: any;
  }
  const columns: MRT_ColumnDef<Candidate & { stageText: string }>[] =
    useMemo(() => {
      const customVariables = candidates.map(
        ({ customVariables }) => customVariables
      );
      const customColumns = Array.from(
        new Set(
          customVariables
            .map((customVariable) => Object.keys(customVariable))
            .flat()
        )
      ).map((customVariableKey) => {
        const spacedHeader = customVariableKey.replace(/([A-Z])/g, " $1");
        const header =
          spacedHeader.charAt(0).toUpperCase() + spacedHeader.slice(1);

        return {
          accessorKey: `customVariables.${customVariableKey}` as any,
          header,
        };
      });
      function filterColumns(
        originalArray: MRT_ColumnDef<Candidate & { stageText: string }>[],
        headersToFilter: string[]
      ) {
        return originalArray.filter(
          (column: MRT_ColumnDef<Candidate & { stageText: string }>) =>
            headersToFilter.includes(column.header)
        );
      }

      // Example usage
      const originalArray: MRT_ColumnDef<Candidate & { stageText: string }>[] =
        [
          {
            accessorKey: "id",
            header: "ID",
            size: 40,
          },
          {
            header: "Profile",
            Cell: ({ cell }: any) => {
              return (
                <>
                  <Button
                    color="primary"
                    onClick={() => {
                      console.log("testtwo", cell.getValue());
                      setSelectedCandidate(
                        candidates.find(
                          (candidate) =>
                            candidate.id === Number(cell.getValue())
                        )
                      );
                      setShowCandidateProfile(true);
                    }}
                    variant="contained"
                  >
                    Show Profile
                  </Button>
                </>
              );
            },
          },
          {
            header: "Actions",
            Cell: ({ cell }: any) => {
              return (
                <>
                  <Button
                    color="primary"
                    onClick={() => {
                      console.log("test edit cell", cell.getValue());
                      setSelectedCandidateId(cell.getValue());
                      handleOpenEditCandidateModal();
                    }}
                    variant="contained"
                  >
                    Edit
                  </Button>
                </>
              );
            },
          },
          {
            accessorKey: "firstName",
            header: "First Name",
            size: 120,
          },
          {
            accessorKey: "lastName",
            header: "Last Name",
            size: 120,
          },
          {
            accessorKey: "email",
            header: "Email",
            enableClickToCopy: true,
            size: 300,
          },
          {
            accessorKey: "linkedinUrl",
            header: "Linkedin URL",
          },
          {
            accessorKey: "stageText",
            header: "Stage",
          },
          // Assuming customColumns is defined somewhere
          ...customColumns,
          {
            accessorKey: "file",
            header: "CV File",
            Cell: ({ cell }: any) => {
              return cell.getValue() ? (
                <Link color="primary" href={cell.getValue()} download>
                  {t("download")}
                </Link>
              ) : null;
            },
          },
        ];

      return filterColumns(originalArray, SelectedColumnsToShow);
    }, [candidates, SelectedColumnsToShow]);

  const csvOptions = {
    filename: "candidates",
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(
    null
  );
  const [anchorElImport, setAnchorElImport] = useState<null | HTMLElement>(
    null
  );
  const [openCreateModal, setCreateOpenModal] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const handleExportRows = (
    rows: MRT_Row<Candidate & { stageText: string }>[]
  ) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(candidates);
  };

  const handleOpenExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElExport(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorElExport(null);
  };

  const handleOpenImportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElImport(event.currentTarget);
  };

  const handleCloseImportMenu = () => {
    setAnchorElImport(null);
  };

  const handleOpenCreateModal = () => setCreateOpenModal(true);

  const handleCloseCreateModal = () => {
    setCreateOpenModal(false);
    setCustomColumns({});
  };

  const handleAddCandidateSubmit = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const cvFile = null;
    const candidateDto = {
      firstName: firstName || "",
      lastName: lastName || "",
      email: email || "",
      linkedinUrl: linkedinUrl || null,
      customVariables: {},
    };

    Object.keys(customColumns)
      .filter((key) => key !== "")
      .forEach(
        (key) =>
          (candidateDto.customVariables = {
            ...candidateDto.customVariables,
            [key]: customColumns[key].value,
          })
      );

    const formData = new FormData();
    formData.append("candidateDto", JSON.stringify([candidateDto]));
    formData.append("cvFile", cvFile as any);
    console.log(formData);
    dispatch(createCandidates(formData as any));
    handleCloseCreateModal();
    setCustomColumns({});
    setEmail("");
    setFirstName("");
    setLastName("");
    setLinkedinUrl("");
    setLinkedinUrl("");
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      Papa.parse(files[0], {
        complete: ({ data }) => {
          const objs = data.slice(1).map((row) => {
            const obj: { [key: string]: string } = {};
            const keys = data[0] as string[];
            for (let i = 0; i < keys.length; i++) {
              if (Array.isArray(row)) {
                if (typeof row[i] === "string") {
                  obj[keys[i]] = row[i];
                }
              }
            }

            return obj;
          });

          setNewCandidates(objs);

          if (fileUploadRef && fileUploadRef.current) {
            fileUploadRef.current.value = "";
            fileUploadRef.current.files = null;
          }
        },
      });
    }
  };

  const handleDeleteCandidates = () => {
    const candidates = Object.keys(rowSelection).map(
      (key) => filteredData[key]
    );
    dispatch(deleteManyCandidates(candidates.map(({ id }) => id)));
    setRowSelection({});
  };

  const handleAssignCandidates = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedCompany && selectedJob) {
      const stageId = companies
        .find((company) => company.id === Number(selectedCompany.value))
        ?.jobs.find((job) => job.id === Number(selectedJob.value))
        ?.stages.find((stage) => stage.order === 1)?.id;

      const email =
        subject.length > 0 && message.length > 0
          ? {
              subject,
              message,
            }
          : undefined;

      const candidateDto = {
        staging: {
          companyId: selectedCompany.value,
          jobId: selectedJob.value,
          stageId,
          emailMessage: email,
        },
      };

      dispatch(
        updateManyCandidates(
          candidatesToAssign.map(({ id }) => id),
          candidateDto as any
        )
      );
      setSubject("");
      setMessage("");
      setSelectedCompany(undefined);
      setSelectedJob(undefined);
      setCandidatesToAssign([]);
    }
  };

  const handleAssignToPageCandidates = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (selectedPage) {
      const candidateDto = {
        pagePath: selectedPage.value,
      };

      dispatch(
        updateManyCandidates(
          candidatesToAssignToPage.map(({ id }) => id),
          candidateDto as any
        )
      );

      setSelectedPage(undefined);
      setCandidatesToAssignToPage([]);
    }
  };

  const handleEditCandidateSubmit = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (
      selectedCandidate &&
      !Object.values({
        ...selectedCandidate,
        ...selectedCandidate.customVariables,
        selectedCandidate: "",
      }).every((value) => value === "" || value === null)
    ) {
      const updateCandidateDto = {
        ...{
          ...selectedCandidate,
          id: undefined,
          companyId: undefined,
          jobId: undefined,
          stage: undefined,
        },
      };
      Object.keys(customColumns)
        .filter((key) => key !== "")
        .forEach(
          (key) =>
            (updateCandidateDto.customVariables = {
              ...updateCandidateDto.customVariables,
              [key]: customColumns[key].value,
            })
        );

      dispatch(
        updateManyCandidates([selectedCandidate.id], updateCandidateDto as any)
      );
    }
    setCustomColumns({});
    handleCloseEditCandidateModal();
  };

  const handleCheckboxChange = (column: string) => {
    const selectedIndex = SelectedColumnsToShow.indexOf(column);
    const newSelectedColumns = [...SelectedColumnsToShow];

    if (selectedIndex === -1) {
      newSelectedColumns.push(column);
    } else {
      newSelectedColumns.splice(selectedIndex, 1);
    }

    setSelectedColumnsToShow(newSelectedColumns);
  };

  const handleSubmitColumns = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // You can use selectedColumns directly wherever you need the updated list of selected columns.
    setSelectedColumnsToShow(SelectedColumnsToShow);
    console.log(SelectedColumnsToShow);
    setShowColumnsEditModal(false);
  };

  const date = filiteredByStagesCandidates.map((candidate) => ({
    ...candidate,
    stageText: !candidate.stage
      ? "does not assigned yet"
      : `Staged at "${candidate.stage.title}" for "${candidate.stage.job.title}" job, by "${candidate.stage.job.company.name}" company`,
  }));

  const filteredData = useMemo(() => {
    return date.filter((candidate) => {
      return (
        candidate.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        candidate.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        candidate.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [date, searchTerm]);

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  useEffect(() => {
    if (user && candidates.length <= 0) {
      dispatch(initializeCandidates());
      dispatch(initializeCompanies());
    }
  }, [candidates, dispatch, user]);

  useEffect(() => {
    setSubject(
      language === "EN"
        ? "A new recommended candidate"
        : "Nový doporučený kandidát"
    );
    setMessage(
      language === "EN"
        ? "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>"
        : "<h1>Byl přidán kandidát</h1><p><br></p><p>Ahoj,</p><p><br></p><p>{{firstName}} {{lastName}} byl přidán do {{title}}</p>"
    );
  }, [language]);

  if (!user) {
    return null;
  }

  return (
    <>
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add User"
        deleteHandler={handleDeleteCandidates}
        deleteBtnLabel="Delete Users"
        addHandler={handleOpenCreateModal}
      />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item md={8} xs={12} sx={{ mb: 3 }}>
          <Table
            columns={columns}
            data={filteredData}
            rowSelection={rowSelection}
            onRowSelectionChange={setRowSelection}
          />
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: {
              xs: "none",
              md: "block",
            },
          }}
        >
          <Box>
            <Typography variant="h6" textAlign="center">
              {t("filterByCompanies")}
            </Typography>
            <Divider />
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: theme.palette.secondary.main,
              }}
            >
              {companies.map(({ name: companyName }) => (
                <ListItem key={companyName}>
                  <ListItemButton
                    role={undefined}
                    onClick={(e) =>
                      filteredCompanies.some(
                        (company) => company === companyName
                      )
                        ? setFilteredCompanies(
                            Array.from(
                              new Set(
                                filteredCompanies.filter(
                                  (company) => company !== companyName
                                )
                              )
                            )
                          )
                        : setFilteredCompanies(
                            Array.from(
                              new Set([...filteredCompanies, companyName])
                            )
                          )
                    }
                    dense
                  >
                    <ListItemIcon>
                      <CheckBox
                        isChecked={filteredCompanies.some(
                          (name) => name === companyName
                        )}
                        onChange={(e) =>
                          e
                            ? setFiliteredJobs(
                                Array.from(
                                  new Set([...filteredJobs, companyName])
                                )
                              )
                            : setFiliteredJobs(
                                Array.from(
                                  new Set(
                                    filteredJobs.filter(
                                      (job) => job !== companyName
                                    )
                                  )
                                )
                              )
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={companyName}
                      primary={companyName}
                      primaryTypographyProps={{ style: { color: "white" } }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box>
            <Typography variant="h6" textAlign="center">
              {t("filterByJobs")}
            </Typography>{" "}
            <Divider />
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: theme.palette.secondary.main,
              }}
            >
              {companies.map(({ jobs, name }) =>
                jobs
                  .filter((job) =>
                    filteredCompanies.length === 0
                      ? true
                      : filteredCompanies.includes(name)
                  )
                  .map((job) => {
                    const jobId = job.id;
                    const jobTitle = job.title;
                    const labelId = `checkbox-list-label-${jobTitle}`;

                    return (
                      <ListItem key={jobId}>
                        <ListItemButton
                          role={undefined}
                          onClick={(e) => {
                            setFiliteredStages([]);
                            return filteredJobs.some((job) => job === jobTitle)
                              ? setFiliteredJobs(
                                  Array.from(
                                    new Set(
                                      filteredJobs.filter(
                                        (job) => job !== jobTitle
                                      )
                                    )
                                  )
                                )
                              : setFiliteredJobs(
                                  Array.from(
                                    new Set([...filteredJobs, jobTitle])
                                  )
                                );
                          }}
                          dense
                        >
                          <ListItemIcon>
                            <CheckBox
                              isChecked={filteredJobs.some(
                                (job) => job === jobTitle
                              )}
                              onChange={(e) =>
                                e
                                  ? setFiliteredJobs(
                                      Array.from(
                                        new Set([...filteredJobs, jobTitle])
                                      )
                                    )
                                  : setFiliteredJobs(
                                      Array.from(
                                        new Set(
                                          filteredJobs.filter(
                                            (job) => job !== jobTitle
                                          )
                                        )
                                      )
                                    )
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={jobTitle}
                            primaryTypographyProps={{
                              style: { color: "white" },
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })
              )}
            </List>
          </Box>
          {filteredJobs.length > 0 && (
            <Box>
              <Typography variant="h6" textAlign="center">
                {t("filterByStages")}
              </Typography>
              <Divider />
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: theme.palette.secondary.main,
                }}
              >
                {companies.map(({ jobs, name }) =>
                  jobs
                    .filter((job) =>
                      filteredCompanies.length === 0
                        ? true
                        : filteredCompanies.includes(name)
                    )
                    .map(({ stages }) => stages)
                    .flat()
                    .filter(
                      (stage, index, stages) =>
                        !stages
                          .slice(index + 1)
                          .some((s) => s.title === stage.title)
                    )
                    .map((stage) => {
                      const stageId = stage.id;
                      const stageTitle = stage.title;
                      const labelId = `checkbox-list-label-${stageTitle}`;

                      return (
                        <ListItem key={stageId}>
                          <ListItemButton
                            role={undefined}
                            onClick={(e) =>
                              filteredStages.some(
                                (stage) => stage === stageTitle
                              )
                                ? setFiliteredStages(
                                    Array.from(
                                      new Set(
                                        filteredStages.filter(
                                          (stage) => stage !== stageTitle
                                        )
                                      )
                                    )
                                  )
                                : setFiliteredStages(
                                    Array.from(
                                      new Set([...filteredStages, stageTitle])
                                    )
                                  )
                            }
                            dense
                          >
                            <ListItemIcon>
                              <CheckBox
                                isChecked={filteredStages.some(
                                  (stage) => stage === stageTitle
                                )}
                                onChange={(e) =>
                                  e
                                    ? setFiliteredStages(
                                        Array.from(
                                          new Set([
                                            ...filteredStages,
                                            stageTitle,
                                          ])
                                        )
                                      )
                                    : setFiliteredStages(
                                        Array.from(
                                          new Set(
                                            filteredStages.filter(
                                              (stage) => stage !== stageTitle
                                            )
                                          )
                                        )
                                      )
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={stageTitle}
                              primaryTypographyProps={{
                                style: { color: "white" },
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })
                )}
              </List>
            </Box>
          )}
        </Grid>
      </Grid>
      {ShowColumnsEditModal && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box component="form" noValidate onSubmit={handleSubmitColumns}>
            <Typography textAlign="center" variant="h4" sx={{ mb: 1 }}>
              {t("SelectColumns")}
            </Typography>
            <Box>
              {[
                "ID",
                "Actions",
                "First Name",
                "Last Name",
                "Email",
                "Linkedin URL",
                "Stage",
                "CV File",
              ].map((column) => (
                <FormControlLabel
                  key={column}
                  control={
                    <CheckBox
                      isChecked={SelectedColumnsToShow.includes(column)}
                      onChange={() => handleCheckboxChange(column)}
                    />
                  }
                  label={column}
                />
              ))}
            </Box>
            <Box sx={{ mb: 3, mt: 7 }}>
              <Box sx={{ mb: 3, mt: 3 }}></Box>
            </Box>
            <Button
              variant="contained"
              color="danger"
              onClick={() => setShowColumnsEditModal(false)}
            >
              {t("close")}
            </Button>
          </Box>
        </Modal>
      )}

      <Modal
        isOpen={openCreateModal}
        onCancel={handleCloseCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          noValidate
          onSubmit={handleAddCandidateSubmit}
          className={styles.modalContent}
        >
          <Typography variant="h4" component="h3">
            {t("addNewCandidates")}
          </Typography>
          <TextField
            required
            fullWidth
            label="First Name"
            name="firstName"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
          <TextField
            required
            fullWidth
            label="Last Name"
            name="lastName"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
          <TextField
            required
            fullWidth
            label="Email Address"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <TextField
            fullWidth
            label="Linkedin URL"
            name="linkedinUrl"
            onChange={(e) => setLinkedinUrl(e.target.value)}
            value={linkedinUrl}
          />
          {Object.entries(customColumns)
            .sort((e1, e2) => e1[1].id - e2[1].id)
            .map((entry) => entry[0])
            .map((columnKey) => (
              <Box key={customColumns[columnKey].id}>
                <TextField
                  label="Column Name"
                  value={columnKey}
                  onChange={(e) => {
                    const newCustomColumns = { ...customColumns };
                    newCustomColumns[e.target.value] =
                      newCustomColumns[columnKey];
                    delete newCustomColumns[columnKey];
                    setCustomColumns(newCustomColumns);
                  }}
                />
                <TextField
                  label="Column Value"
                  value={customColumns[columnKey].value}
                  onChange={(e) => {
                    const newCustomColumns = { ...customColumns };
                    newCustomColumns[columnKey] = {
                      id: newCustomColumns[columnKey].id,
                      value: e.target.value,
                    };
                    setCustomColumns(newCustomColumns);
                  }}
                />
              </Box>
            ))}

          <input
            type="file"
            ref={ref}
            style={{ display: "none" }}
            id="cvFile"
            name="cvFile"
            accept="application/msword, text/plain, application/pdf"
          />
          <Box className={styles.buttons}>
            <Button
              startIcon={<UploadFile />}
              color="primary"
              variant="contained"
              onClick={() => {
                ref.current?.click();
              }}
            >
              {t("uploadCSV")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={(e) => {
                if (Object.keys(customColumns).every((key) => key !== "")) {
                  const newCustomColumns = customColumns;
                  newCustomColumns[""] = {
                    id: Object.keys(customColumns).length,
                    value: "",
                  };
                  setCustomColumns({ ...newCustomColumns });
                }
              }}
            >
              Add Column
            </Button>

            <Button type="submit" color="primary">
              {t("addCandidates")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                handleCloseCreateModal();
              }}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
      {candidatesToAssign.length > 0 && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box component="form" noValidate onSubmit={handleAssignCandidates}>
            <Typography textAlign="center" variant="h4" sx={{ mb: 1 }}>
              {t("assignCandidates")}
            </Typography>
            <Box sx={{ mb: 3, mt: 7 }}>
              {" "}
              <Box sx={{ mb: 3, mt: 3 }}>
                <ReactSelect
                  placeholder={t("selectCompany")}
                  options={companies.map((company) => ({
                    label: company.name,
                    value: company.id,
                  }))}
                  onChange={(option) => setSelectedCompany(option as any)}
                />
              </Box>
              <ReactSelect
                placeholder={t("selectJob")}
                options={companies
                  .find((company) => company.id === selectedCompany?.value)
                  ?.jobs.map((job) => ({
                    label: job.title,
                    value: job.id,
                  }))}
                onChange={(option) => setSelectedJob(option as any)}
              />
            </Box>
            <Box sx={{ mb: 3 }}>
              <ChildModal
                subject={subject}
                message={message}
                setSubject={setSubject}
                setMessage={setMessage}
                language={language}
                setLanguage={setLanguage}
              />
            </Box>
            <Button
              type="submit"
              color="success"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("assignCandidates")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                setSelectedCompany(undefined);
                setSelectedJob(undefined);
                setCandidatesToAssign([]);
                setSubject("");
                setMessage("");
              }}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Modal>
      )}
      <Drawer
        DrawerContent={<CandidateProfile candidateId={2} />}
        openDrawer={showCandidateProfile}
        setOpenDrawer={setShowCandidateProfile}
        anchor="right"
      />
      {showCandidateProfile && (
        <CandidateProfile
          candidateId={2}
          close={() => setShowCandidateProfile(false)}
        />
      )}
      {candidatesToAssignToPage.length > 0 && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleAssignToPageCandidates}
          >
            <Typography textAlign="center" variant="h4" sx={{ mb: 1 }}>
              {t("assignCandidates")}
            </Typography>
            <Box sx={{ mb: 3, mt: 7 }}>
              {" "}
              <Box sx={{ mb: 3, mt: 3 }}>
                <CreatableSelect
                  placeholder="Select page"
                  options={Array.from(
                    new Set(
                      candidates
                        .map((candidate) => candidate.pagePath)
                        .filter((pagePath) => pagePath !== null)
                    )
                  ).map((pagePath) => ({ label: pagePath, value: pagePath }))}
                  onChange={(option) => setSelectedPage(option as any)}
                />
              </Box>
            </Box>
            <Button
              type="submit"
              color="success"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("assignCandidates")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                setSelectedPage(undefined);
                setCandidatesToAssignToPage([]);
              }}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Modal>
      )}
      {newCandidates && (
        <AddCandidatesForm
          newCandidates={newCandidates}
          resetNewCandidates={() => setNewCandidates(null)}
        />
      )}
      <input
        type="file"
        accept=".csv,.xlsx,.xls"
        style={{ display: "none" }}
        ref={fileUploadRef}
        onChange={handleFileUpload}
      />
      <Modal
        isOpen={openEditCandidateMdoel}
        onCancel={() => {
          setCustomColumns({});
          handleCloseEditCandidateModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          noValidate
          className={styles.modalContent}
          onSubmit={handleEditCandidateSubmit}
        >
          <Typography textAlign="center" variant="h4" sx={{ mb: 1 }}>
            Edit Candidate
          </Typography>
          {selectedCandidate &&
            Object.keys(selectedCandidate)
              .filter(
                (key) =>
                  key !== "id" &&
                  key !== "customVariables" &&
                  key !== "companyId" &&
                  key !== "jobId" &&
                  key !== "stage" &&
                  key !== "file"
              )
              .map((key, index) => {
                const value = selectedCandidate[
                  key as keyof Candidate
                ] as string;
                return (
                  <TextField
                    key={index}
                    fullWidth
                    label={key}
                    value={value}
                    onChange={(e) =>
                      setSelectedCandidate({
                        ...selectedCandidate,
                        [key]: e.currentTarget.value,
                      })
                    }
                  />
                );
              })}
          {selectedCandidate &&
            selectedCandidate.customVariables &&
            Object.keys(selectedCandidate.customVariables)
              .filter((key) => key !== "emailStatus")
              .map((key, index) => {
                return (
                  <TextField
                    key={Object.keys(selectedCandidate).length + index}
                    fullWidth
                    label={key}
                    value={selectedCandidate.customVariables[key]}
                    onChange={(e) =>
                      setSelectedCandidate({
                        ...selectedCandidate,
                        customVariables: {
                          ...selectedCandidate.customVariables,
                          [key]: e.currentTarget.value,
                        },
                      })
                    }
                  />
                );
              })}
          {Object.entries(customColumns)
            .sort((e1, e2) => e1[1].id - e2[1].id)
            .map((entry) => entry[0])
            .map((columnKey) => (
              <Box
                key={customColumns[columnKey].id}
                className={styles.keyValue}
              >
                <TextField
                  label="Column Name"
                  value={columnKey}
                  onChange={(e) => {
                    const newCustomColumns = { ...customColumns };
                    newCustomColumns[e.target.value] =
                      newCustomColumns[columnKey];
                    delete newCustomColumns[columnKey];
                    setCustomColumns(newCustomColumns);
                  }}
                />
                <TextField
                  label="Column Value"
                  value={customColumns[columnKey].value}
                  onChange={(e) => {
                    const newCustomColumns = { ...customColumns };
                    newCustomColumns[columnKey] = {
                      id: newCustomColumns[columnKey].id,
                      value: e.target.value,
                    };
                    setCustomColumns(newCustomColumns);
                  }}
                />
              </Box>
            ))}
          <input
            type="file"
            ref={ref}
            style={{ display: "none" }}
            id="cvFile"
            name="cvFile"
            accept="application/msword, text/plain, application/pdf"
            onChange={async (e) => {
              if (e.currentTarget.files && e.currentTarget.files[0]) {
                setSelectedCandidate({
                  ...selectedCandidate,
                  file: (await toBase64(e.currentTarget.files[0])) as string,
                } as any);
              }
            }}
          />
          {selectedCandidate?.file && (
            <Link
              display="block"
              textAlign="center"
              color="primary"
              href={selectedCandidate.file}
              download
            >
              Download CSV File
            </Link>
          )}
          <Box className={styles.buttons}>
            <Button
              startIcon={<UploadFile />}
              color="primary"
              variant="contained"
              onClick={() => {
                ref.current?.click();
              }}
            >
              {t("uploadCSV")}
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={(e) => {
                if (Object.keys(customColumns).every((key) => key !== "")) {
                  const newCustomColumns = customColumns;
                  newCustomColumns[""] = {
                    id: Object.keys(customColumns).length,
                    value: "",
                  };
                  setCustomColumns({ ...newCustomColumns });
                }
              }}
            >
              Add Column
            </Button>

            <Button type="submit" color="success" variant="contained">
              Edit
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                handleCloseEditCandidateModal();
                setCustomColumns({});
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
