import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../utils/store";
import { notify } from "./notification";
import { NotificationLevel } from "../types/Notification";
import { AxiosError } from "axios";
import Candidate from "../types/Candidate";
import candidateService, {
  CreateCandidateDto,
  UpdateCandidateDto,
} from "../services/candidates";
import { initializeCompanies } from "./companies";

const initialState = [] as Candidate[];

const candidatesSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    setCandidates(state, { payload: candidates }: PayloadAction<Candidate[]>) {
      return candidates;
    },
    addCandidates(state, { payload: candidates }: PayloadAction<Candidate[]>) {
      return state.concat(candidates);
    },
    replaceOne(state, { payload: updatedCandidate }: PayloadAction<Candidate>) {
      return state.map((candidate) =>
        candidate.id === updatedCandidate.id ? updatedCandidate : candidate
      );
    },
    removeCandidate(state, { payload: id }: PayloadAction<number>) {
      return state.filter((candidate) => candidate.id !== id);
    },
    clearCandidates(state) {
      return [];
    },
  },
});

export const {
  setCandidates,
  addCandidates,
  replaceOne,
  removeCandidate,
  clearCandidates,
} = candidatesSlice.actions;

export const initializeCandidates = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const candidates = await candidateService.getAll();
      dispatch(setCandidates(candidates));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.log("test", error);
        dispatch(
          notify({
            message: `Failed to fetch candidates`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const createCandidates = (createCandidateDtos: CreateCandidateDto[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      const candidates = await candidateService.createMany(createCandidateDtos);
      dispatch(
        notify({
          message: "New candidates were added successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
      dispatch(addCandidates(candidates));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to add candidates. ${error.response?.data?.message?.join(
              ". "
            )}`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const updateOneCandidate = (
  id: number,
  updateCandidateDto: UpdateCandidateDto,
  me = false
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const candidates = await candidateService.updateOne(
        id,
        updateCandidateDto
      );
      dispatch(initializeCompanies(me));
      dispatch(
        notify({
          message: "Candidate is updated successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
      dispatch(replaceOne(candidates));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to  update the candidate. ${error.response?.data?.message?.join(
              ". "
            )}`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const updateManyCandidates = (
  ids: number[],
  updateCandidateDto: UpdateCandidateDto,
  me = false
) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await candidateService.updateOne(id, updateCandidateDto);
      }

      dispatch(initializeCompanies(me));
      if (!me) {
        dispatch(initializeCandidates());
      }

      dispatch(
        notify({
          message: "Candidate is updated successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to add update the candidate. ${error.response?.data?.message?.join(
              ". "
            )}`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const deleteManyCandidates = (ids: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await candidateService.deleteOne(id);
        dispatch(removeCandidate(id));
      }
      dispatch(initializeCompanies());
      dispatch(
        notify({
          message: "Candidates are removed successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to remove candidates`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export default candidatesSlice.reducer;
