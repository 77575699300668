import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../types/User";
import { AppDispatch } from "../utils/store";
import authService, { Credentials } from "../services/auth";
import { notify } from "./notification";
import { NotificationLevel } from "../types/Notification";
import { AxiosError } from "axios";
import { setToken } from "../utils/axios";

const initialState = null as User | null;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser(state, { payload: user }: PayloadAction<User>) {
      return user;
    },
    clearUser(state) {
      return null;
    },
  },
});

export const { addUser, clearUser } = userSlice.actions;

export const login = (credentials: Credentials, remember = true) => {
  return async (dispatch: AppDispatch) => {
    try {
      const userWithToken = await authService.login(credentials);
      dispatch(
        notify({
          message: "Signed in successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
      setToken(userWithToken.accessToken);
      dispatch(addUser(userWithToken.user));
      if (remember) {
        localStorage.setItem("auth", JSON.stringify(userWithToken));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to login. ${error.response?.data.message}`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const logout = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(clearUser());
    localStorage.removeItem("auth");
    setToken("");
    dispatch(
      notify({
        message: "Signed out successfully",
        level: NotificationLevel.SUCCESS,
      })
    );
  };
};

export default userSlice.reducer;
