import { Container as MuiContainer } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

export default function Container({ children }: Props) {
  return <MuiContainer sx={{ paddingTop: ['2rem','3rem'] }}>{children}</MuiContainer>;
}
