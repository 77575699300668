import React from 'react';
import ReactSelect from 'react-select';
import { selectStyles } from './react-select-style';
type Option = {
  label: string | undefined;
  value: string | undefined;
};

export interface SelectProps {
  placeholder?: string;
  options: Option[];
  value?: Option | Option[];
  onChange: (campaigns?: any) => void;
  isMulti?: boolean;
  isSearchable?: boolean;
}

const Select: React.FC<SelectProps> = ({ placeholder = 'Select', options, isMulti = false, value, onChange, isSearchable }) => {
  return (
    <ReactSelect
      styles={selectStyles}
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      isSearchable={isSearchable}
    />
  );
};

export default Select;
