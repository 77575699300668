import ReactSelect from "react-select";
import { type MRT_ColumnDef, type MRT_Row } from "material-react-table";
import Table from "../../components/elements/Table";
import { ThemeProvider, createTheme, useTheme } from "@mui/material";

import { ExportToCsv } from "export-to-csv";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/UI/Layout";
import { AppDispatch, RootState } from "../../utils/store";
import { useEffect, useMemo, useState } from "react";
import { Box, FormControl, Grid, Typography } from "@mui/material";
import Select from "../../components/UI/Select";
import Button from "../../components/UI/Button";
import Modal from "../../components/UI/Modal";
import TextField from "../../components/UI/TextField";
import {
  initializeCustomUsers,
  createOneCustomUser,
  deleteCustomUser,
  deleteManyCustomUsers,
} from "../../reducers/customUsers";
import { updateCompany } from "../../reducers/companies";
import { CreateCustomUserDto, UserRole, customUser } from "../../types/User";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { initializeRoles } from "../../reducers/role";
import ActionsBar from "../../components/elements/ActionsBar";
import styles from "./Modal.module.scss";

export default function Users() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [language, setLanguage] = useState<"EN" | "CZ">("EN");
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  );
  const [t] = useTranslation(["common"]);
  const [subject, setSubject] = useState<string>("A new recommended candidate");
  const [message, setMessage] = useState<string>(
    "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>"
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState({ label: "", value: "" });
  const { user, customUsers, roles } = useSelector((state: RootState) => ({
    user: state.user,
    customUsers: state.customUsers,
    roles: state.roles,
  }));
  const [searchTerm, setSearchTerm] = useState("");
  console.log(customUsers);
  const [selectedCandidates, setSelectedCandidates] = useState<
    { label: string; value: number }[]
  >([]);
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [selectedJob, setSelectedJob] = useState<{
    label: string;
    value: number;
  }>();
  const columns: MRT_ColumnDef<customUser>[] = useMemo(
    () =>
      [
        {
          accessorKey: "id",
          header: "ID",
          size: 40,
        },

        {
          accessorKey: "email",
          header: "Email",
          size: 120,
        },
        {
          accessorKey: "role",
          header: "Role",
          size: 120,
        },
      ] as any,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customUsers]
  );

  const csvOptions = {
    filename: "Users",
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(
    null
  );
  const [openCreateModal, setCreateOpenModal] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  const dispatch: AppDispatch = useDispatch();

  const handleExportRows = (rows: MRT_Row<customUser>[]) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(customUsers);
  };

  const handleOpenExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElExport(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorElExport(null);
  };

  const handleOpenCreateModal = () => setCreateOpenModal(true);

  const handleCloseCreateModal = () => setCreateOpenModal(false);

  const handleAddUserSubmit = () => {
    console.log(email, password, role);

    if (email && password && role) {
      const user: CreateCustomUserDto = {
        role: role.value,
        email,
        password,
      };
      console.log("dispatch", user);
      dispatch(createOneCustomUser(user));
    }

    handleCloseCreateModal();
  };

  const handleEditCompanySubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const companyDto = {
      name: data.get("companyName")?.toString() || "",
      language: data.get("companyLanguage")?.toString() || "EN",
    };
    dispatch(updateCompany(selectedCompanyId as number, companyDto));
    setSelectedCompanyId(null);
  };

  const filteredCustomUsers = useMemo(() => {
    return customUsers.filter((customUser) => {
      return (
        customUser.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customUser.role.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [customUsers, searchTerm]);
  const handleDeleteCustomUser = () => {
    const customUsers = Object.keys(rowSelection).map(
      (key) => filteredCustomUsers[key]
    );
    console.log(customUsers);
    dispatch(
      deleteManyCustomUsers(customUsers.map((customUser) => customUser.id))
    );
    setRowSelection({});
  };

  console.log(Object.keys(rowSelection).map((key) => filteredCustomUsers[key]));

  console.log("rowSelection", rowSelection);

  useEffect(() => {
    if (user) {
      dispatch(initializeCustomUsers());
      dispatch(initializeRoles());
    }
  }, []);

  useEffect(() => {
    setSubject(
      language === "EN"
        ? "A new recommended candidate"
        : "Nový doporučený kandidát"
    );
    setMessage(
      language === "EN"
        ? "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>"
        : "<h1>Byl přidán kandidát</h1><p><br></p><p>Ahoj,</p><p><br></p><p>{{firstName}} {{lastName}} byl přidán do {{title}}</p>"
    );
  }, [language]);

  return (
    <>
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add User"
        deleteHandler={handleDeleteCustomUser}
        deleteBtnLabel="Delete Users"
        addHandler={handleOpenCreateModal}
      />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Table
            onRowSelectionChange={setRowSelection}
            columns={columns}
            data={filteredCustomUsers}
            rowSelection={rowSelection}
            enableColumnOrdering
            enableSorting
            enableColumnActions
          />
        </Grid>
      </Grid>
      <Modal
        isOpen={openCreateModal}
        onCancel={handleCloseCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          noValidate
          className={styles.modalContent}
          onSubmit={handleAddUserSubmit}
        >
          <Typography variant="h4" component="h3">
            Add New User
          </Typography>
          <TextField
            required
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            label={t("email")}
            name="email"
          />
          <FormControl fullWidth>
            <Select
              placeholder="Select Role"
              onChange={(e) => setRole(e)}
              isSearchable
              value={role}
              options={roles.map((role) => ({
                label: role.name,
                value: role.name,
              }))}
            />
          </FormControl>
          <TextField
            required
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            type="password"
            label={t("password")}
            name="password"
          />

          <Box className={styles.buttons}>
            <Button type="submit" color="primary" className={styles.add}>
              {t("addUser")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => handleCloseCreateModal()}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
