import { Job, Stage } from "../types/Company";
import axios from "../utils/axios";
import config from "../utils/config";

const baseUrl = `${config.backendUrl}/stages`;

export type CreateStageDto = {
  title: string;
  order: number;
};

const create = async (
  jobId: number,
  createStageDto: CreateStageDto
): Promise<Stage> => {
  const { data } = await axios.post<Stage>(
    `${baseUrl}?jobId=${jobId}`,
    createStageDto
  );
  return data;
};
const update = async (
  jobId: number,
  stageId: number,
  updateStageDto: CreateStageDto
): Promise<Stage> => {
  const { data } = await axios.patch<Stage>(
    `${baseUrl}/${stageId}`,
    updateStageDto
  );
  return data;
};
const remove = async (jobId: number, stageId: number): Promise<Stage> => {
  const { data } = await axios.delete<Stage>(`${baseUrl}/${stageId}`);
  return data;
};

const stagesService = { create, update, remove };

export default stagesService;
