import axios from "axios";

let token: string = "";

export const setToken = (newToken: string): void => {
  token = `Bearer ${newToken}`;
  axios.defaults.headers.common.Authorization = token;
};

export default axios;
