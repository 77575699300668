import Candidate from "../types/Candidate";
import Company from "../types/Company";
import axios from "../utils/axios";
import config from "../utils/config";

const baseUrl = `${config.backendUrl}/companies`;

export type CreateCompanyDto = {
  name: string;
  users: {
    email: string;
    password: string;
    role?: string; // Making role optional
  }[];
};

export type UpdateCompanyDto = {
  name: string;
};

const create = async (createCompanyDto: CreateCompanyDto): Promise<Company> => {
  // Provide default values for the users if not specified
  const { users } = createCompanyDto;
  const defaultRole = "client";
  const usersWithDefaultRole = users.map((user) => ({
    ...user,
    role: user.role || defaultRole,
  }));

  const { data } = await axios.post<Company>(baseUrl, {
    ...createCompanyDto,
    users: usersWithDefaultRole,
  });
  return data;
};

const getAll = async (): Promise<Company[]> => {
  const { data } = await axios.get<Company[]>(baseUrl);
  return data;
};

const getOne = async (id: number): Promise<Company> => {
  const { data } = await axios.get<Company>(`${baseUrl}/${id}`);
  return data;
};

const updateOne = async (
  id: number,
  updateCompanyDto: UpdateCompanyDto
): Promise<Company> => {
  const { data } = await axios.patch<Company>(
    `${baseUrl}/${id}`,
    updateCompanyDto
  );
  return data;
};

const deleteOne = async (id: number): Promise<Company> => {
  const { data } = await axios.delete<Company>(`${baseUrl}/${id}`);
  return data;
};

const me = async (): Promise<Company[]> => {
  const { data } = await axios.get<Company>(`${baseUrl}/me`);
  return [data];
};

const candidateService = { create, getAll, getOne, updateOne, deleteOne, me };

export default candidateService;
