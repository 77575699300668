export const selectStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: '#161B2E',
    color: 'white',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#1A2338' : '#161B2E',
    color: 'white',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#161B2E',
    zIndex: 10000000,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#161B2E',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
};
