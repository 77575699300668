export enum UserRole {
  ADMIN = "admin",
  CLIENT = "client",
  RECRUITER = "recruiter",
}

export interface User {
  id: number;
  email: string;
  role: UserRole;
}
export interface CreateUserDto {
  email: string;
  password: string;
  role?: UserRole;
}

export interface customUser {
  id: number;
  email: string;
  role: string;
}
export interface CreateCustomUserDto {
  email: string;
  password: string;
  role: string;
}
