import { CreateRecruiter } from "../reducers/recruiters";
import { User, UserRole, CreateUserDto } from "../types/User";
import axios from "../utils/axios";
import config from "../utils/config";

const baseUrl = `${config.backendUrl}/users`;

const getAll = async (): Promise<User[]> => {
  const { data } = await axios.get<User[]>(`${baseUrl}/role/recruiter`);
  return data;
};

const getOne = async (id: number): Promise<User> => {
  const { data } = await axios.get<User>(`${baseUrl}/${id}`);
  return data;
};

const deleteOne = async (id: number): Promise<User> => {
  const { data } = await axios.delete<User>(`${baseUrl}/${id}`);
  return data;
};

const addOne = async (user: CreateUserDto): Promise<User> => {
  const userWithRole: CreateUserDto = {
    ...user,
    role: UserRole.RECRUITER,
  };

  const { data } = await axios.post<User>(
    `${baseUrl}/with-permissions`,
    userWithRole
  );
  return data;
};

const recruitersService = {
  getAll,
  getOne,
  addOne,
  deleteOne,
};

export default recruitersService;
