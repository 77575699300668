import { Button as MuiButton, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { ReactNode } from 'react';
export interface ButtonProps {
  onClick?: (() => void) | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  color?: 'primary' | 'secondary' | 'danger' | 'success';
  size?: 'small' | 'large';
  children?: ReactNode;
  sx?: SxProps;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  disabled,
  variant = 'text',
  startIcon,
  endIcon,
  color = 'primary',
  size = 'small',
  children,
  sx: customStyles = {},
  type = 'button',
  ...props
}) => {
  const theme = useTheme();

  const resolvedColor =
    color === 'primary'
      ? theme.palette.primary.dark
      : color === 'secondary'
        ? theme.palette.secondary.dark
        : color === 'danger'
          ? theme.palette.error.dark
          : color === 'success'
            ? theme.palette.success.main
            : color;

  const resolvedHoverColor =
    color === 'primary'
      ? theme.palette.primary.light
      : color === 'secondary'
        ? theme.palette.secondary.light
        : color === 'danger'
          ? theme.palette.error.light
          : color === 'success'
            ? theme.palette.success.light
            : color;

  const defaultStyles = {
    padding: size === 'small' ? '4px 16px' : size === 'large' ? '10px 60px' : '10px 20px',
    fontSize: size === 'small' ? '14px' : '16px',
    fontWeight: 600,
    bgcolor: `${resolvedColor} !important`,
    color: '#fff',
    ':hover': {
      bgcolor: `${resolvedHoverColor} !important`,
      color: 'white',
    },
  };

  return (
    <MuiButton
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      sx={{ ...defaultStyles, ...customStyles }}
      type={type}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
