import Button from '../../UI/Button';
import TextField from '../../UI/TextField';
import { Box, Grid } from '@mui/material';
import React, { MouseEvent } from 'react';
import { GrAdd } from 'react-icons/gr';
import { TbTrash } from 'react-icons/tb';

export interface Props {
  className?: string;
  addBtnElement?: React.ReactNode;
  children?: React.ReactNode;
  showMenu?: boolean;
  isMenuOpen?: boolean;
  showDelete?: boolean;
  useVerticalDotMenuIcon?: boolean;
  addBtnLabel?: string;
  deleteBtnLabel?: string;
  addHandler?: (e?: any) => void;
  deleteHandler?: (e?: any) => void;
  menuHandler?: (e?: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  searchHandler: (searchTerm: string) => void;
}

export default function ActionsBar({
  className,
  addBtnElement,
  children,
  searchHandler,
  addBtnLabel,
  deleteBtnLabel,
  addHandler,
  deleteHandler,
  showDelete,
  showMenu,
  isMenuOpen,
  menuHandler,
  useVerticalDotMenuIcon = false,
}: Props) {
  const smAndUpWidth = showMenu ? '100%' : 'fit-content';
  // const mdAndUpWidth = showMenu ? 'fit-content' : '100%';
  const mdAndUpWidth = 'fit-content';

  return (
    <Grid
      container
      spacing={2}
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{ mb: 2 }}
      className={className}
    >
      <Grid item xs={showMenu ? 6 : 12} sm={5}>
        <TextField
          sx={{ maxWidth: '18rem' }}
          type="search"
          label="search"
          onChange={(e) => searchHandler(e.target.value)}
        />
      </Grid>
      {children && (
        <Grid item xs={3} textAlign={'right'}>
          {children}
        </Grid>
      )}

      <Grid item sx={showMenu ? { display: { md: 'none' } } : { display: 'none' }}>
        <Button type="submit" onClick={menuHandler} sx={isMenuOpen ? {} : { bgcolor: 'transparent' }}>
        </Button>
      </Grid>

      <Grid item xs={12} sm={showMenu ? 12 : 4} md={4} textAlign={'right'}>

        {showDelete ? (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              sx={{ width: { xs: '100%', sm: smAndUpWidth, md: mdAndUpWidth } }}
              color="danger"
              onClick={deleteHandler}
              startIcon={<TbTrash />}
            >
              {deleteBtnLabel}
            </Button>
            <Button
              sx={{ width: { xs: '100%', sm: smAndUpWidth, md: mdAndUpWidth } }}
              startIcon={<GrAdd />}
              onClick={addHandler}
            >
              {addBtnLabel}
            </Button>
          </Box>

        ) : (
          addBtnElement || (
            <Button
              sx={{ width: { xs: '100%', sm: smAndUpWidth, md: mdAndUpWidth } }}
              startIcon={<GrAdd />}
              onClick={addHandler}
            >
              {addBtnLabel}
            </Button>
          )
        )}
      </Grid>
    </Grid>
  );
}
