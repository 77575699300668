import ReactSelect from "react-select";
import { type MRT_ColumnDef, type MRT_Row } from "material-react-table";

import { ExportToCsv } from "export-to-csv";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import styles from "./Modal.module.scss";

import {
  updateManyCandidates,
  updateOneCandidate,
} from "../../reducers/candidates";
import { Box, Grid, Link, Typography } from "@mui/material";
import Modal from "../../components/UI/Modal";
import Candidate from "../../types/Candidate";
import { initializeCompanies } from "../../reducers/companies";
import { UserRole } from "../../types/User";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import stagesService from "../../services/stages";
import Table from "../../components/elements/Table";
import Select from "../../components/UI/Select";
import TextField from "../../components/UI/TextField";
import Button from "../../components/UI/Button";
import ActionsBar from "../../components/elements/ActionsBar";
import StagesSwitcher from "../../components/elements/StagesSwitcher";

// Define an interface for the stage object
interface Stage {
  id: number;
  title: string;
  order: number;
  candidates: Candidate[]; // Update candidates type to match the actual type
}

export default function Stages() {
  const theme = useTheme();
  const [t] = useTranslation(["common"]);
  const jobId = useParams().id;
  const [jobTitle, setJobTitle] = useState("");
  const [addStageName, setAddStageName] = useState("");
  const [editTitleStage, setEditTitleStage] = useState("");
  const { user, companies } = useSelector((state: RootState) => ({
    user: state.user,
    companies: state.companies,
  }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const job = companies
    .map((company) => company.jobs)
    ?.flat()
    ?.find((job) => job.id === Number(jobId));
  const candidates = job?.stages
    .map((stage) => ({
      candidates: stage.candidates.map((candidate) => ({
        ...candidate,
        stage: {
          id: stage,
          title: stage.title,
          order: stage.order,
          job: {
            job: job.id,
            title: job.title,
          },
        },
      })),
    }))
    .map((stage) => stage.candidates)
    .flat();
  const [selectedCandidatesIds, setSelectedCandidatesIds] = useState<number[]>(
    []
  );

  const [selectedEditStage, setSelectedEditStage] = useState<Stage | null>(
    null
  );

  console.log("candidates", candidates);
  console.log(
    "job",
    job?.stages
      .slice()
      .sort((a, b) => a.order - b.order)
      .map(({ id, title, order }) => ({ id, title, order }))
  );
  const stagesNoRepetitions = job?.stages
    .slice()
    .sort((a, b) => a.order - b.order)
    .map(({ id, title, order }) => ({ id, title, order }));
  console.log("stagesNoRepetitions", stagesNoRepetitions);
  const [newOrder, setNewOrder] = useState(() => stagesNoRepetitions || []);

  const [selectedStage, setSelectedStage] = useState<
    | {
        label: string;
        value: number;
      }
    | undefined
  >();

  const [selectedDeleteStage, setSelectedDeleteStage] = useState<Stage | null>(
    null
  );
  const columns: MRT_ColumnDef<Candidate & { stageText: string }>[] =
    useMemo(() => {
      const customVariables = candidates?.map(
        ({ customVariables }) => customVariables
      );
      const customColumns = Array.from(
        new Set(
          customVariables
            ?.map((customVariable) => Object.keys(customVariable))
            .flat()
        )
      ).map((customVariableKey) => {
        const spacedHeader = customVariableKey.replace(/([A-Z])/g, " $1");
        const header =
          spacedHeader.charAt(0).toUpperCase() + spacedHeader.slice(1);

        return {
          accessorKey: `customVariables.${customVariableKey}` as any,
          header,
        };
      });

      return [
        {
          accessorKey: "id",
          header: "ID",
          size: 40,
        },
        {
          header: "Actions",
          Cell: ({ row }: any) => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSelectedCandidatesIds([row.original.id]);
                handleOpenStagingModal();
                console.log(row.original.id);
              }}
            >
              Move to Stage
            </Button>
          ),
        },
        {
          accessorKey: "firstName",
          header: "First Name",
          size: 120,
        },
        {
          accessorKey: "lastName",
          header: "Last Name",
          size: 120,
        },
        {
          accessorKey: "email",
          header: "Email",
          enableClickToCopy: true,
          size: 300,
        },
        {
          accessorKey: "linkedinUrl",
          header: "Linkedin URL",
        },
        ...customColumns,
        {
          accessorKey: "file",
          header: "CV File",
          Cell: ({ cell }: any) => {
            return cell.getValue() ? (
              <Link color="primary" href={cell.getValue()} download>
                {t("download")}
              </Link>
            ) : null;
          },
        },

        {
          header: "Order",
          accessorKey: "order",
        },
        {
          header: "Hidden",
          accessorKey: "isHidden",
        },
      ] as any;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candidates]);
  interface CustomColumnDef<T> {
    accessorKey: keyof T;
    header: string;
    // You can add other properties if needed
  }

  // Assuming orderingStagesColumns is defined as an array of MRT_ColumnDef
  const orderingStagesColumns: CustomColumnDef<{
    id: number;
    title: string;
    order: number;
  }>[] = [
    { accessorKey: "title", header: "Stage Title" },
    // Add other columns if needed
  ];
  const csvOptions = {
    filename: "candidates",
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(
    null
  );
  const [openStagingModal, setOpenStagingModal] = useState(false);
  const [openAddStageModal, setOpenAddStageModal] = useState(false);
  const [openEditStageModal, setOpenEditStageModal] = useState(false);
  const [openDeleteStageModal, setOpenDeleteStageModal] = useState(false);
  const [openOrderStageModal, setOpenOrderStageModal] = useState(false);
  const [activeStage, setActiveStage] = useState<Stage>();
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch: AppDispatch = useDispatch();

  const handleExportRows = (
    rows: MRT_Row<Candidate & { stageText: string }>[]
  ) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(candidates);
  };

  const handleOpenExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElExport(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorElExport(null);
  };

  const handleOpenStagingModal = () => setOpenStagingModal(true);

  const handleCloseStagingModal = () => setOpenStagingModal(false);

  const handleOpenAddStageModal = () => setOpenAddStageModal(true);

  const handleCloseAddStageModal = () => setOpenAddStageModal(false);

  const handleOpenDeleteStageModal = () => {
    setOpenDeleteStageModal(true);
  };
  const handleCloseDeleteStageModal = () => {
    setSelectedDeleteStage(null);
    setOpenDeleteStageModal(false);
  };

  const handleOpenEditStageModal = () => {
    console.log("selectedStage", selectedStage);
    setOpenEditStageModal(true);
  };

  const handleCloseEditStageModal = () => {
    setSelectedEditStage(null);
    setOpenEditStageModal(false);
  };

  const handleOpenOrderStageModal = () => {
    setOpenOrderStageModal(true);
  };
  const handleCloseOrderStageModal = () => {
    setOpenOrderStageModal(false);
  };

  const handleStaginCandidate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedCandidatesIds) {
      const candidateDto = {
        staging: {
          companyId: companies[0].id,
          jobId: job?.id,
          stageId: selectedStage?.value,
        },
      };

      dispatch(
        updateManyCandidates(
          selectedCandidatesIds.filter((id) => typeof id === "number"),
          candidateDto as any,
          user?.role === UserRole.CLIENT
        )
      );
      setSelectedCandidatesIds([]);
      setSelectedStage(undefined);
      handleCloseStagingModal();
    }
  };

  const handleAddStage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const title = addStageName;
    if (job) {
      stagesService
        .create(job?.id, {
          title,
          order: job.stages.length + 1,
        })
        .then(() => {
          dispatch(initializeCompanies(user?.role === UserRole.CLIENT));
        });
    }
    handleCloseAddStageModal();
  };
  const handleDeleteStage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (job) {
      if (selectedDeleteStage) {
        stagesService.remove(job?.id, selectedDeleteStage?.id).then(() => {
          dispatch(initializeCompanies(user?.role === UserRole.CLIENT));
        });
      }
    }

    setSelectedDeleteStage(null);
    handleCloseDeleteStageModal();
  };
  const handleEditStage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedEditStage) {
      const title = editTitleStage;
      if (job && selectedEditStage?.id !== undefined) {
        stagesService
          .update(job?.id, selectedEditStage?.id, {
            title: title,
            order: selectedEditStage?.order,
          })
          .then(() => {
            dispatch(initializeCompanies(user?.role === UserRole.CLIENT));
          });
      }
      setSelectedEditStage(null);
      handleCloseEditStageModal();
    }
  };

  const handleOrderStages = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    newOrder.forEach((stage, index) => {
      if (job) {
        stagesService
          .update(job?.id, stage.id, {
            title: stage.title,
            order: index + 1,
          })
          .then(() => {
            dispatch(initializeCompanies(user?.role === UserRole.CLIENT));
          });
      }
    });
    handleCloseOrderStageModal();
  };

  useEffect(() => {
    if (user && companies?.length <= 0) {
      dispatch(initializeCompanies(user.role === UserRole.CLIENT));
    }
  }, [companies, user, dispatch]);

  const filteredData = useMemo(() => {
    return activeStage?.candidates.filter((candidate) => {
      return (
        candidate.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        candidate.lastName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [activeStage, searchTerm, selectedCandidatesIds]);

  return (
    <>
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={true}
        addBtnLabel="Add Stage"
        deleteBtnLabel="Delete Stage"
        deleteHandler={handleOpenDeleteStageModal}
        addHandler={handleOpenAddStageModal}
        children={
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenEditStageModal}
            >
              {t("Edit Stages")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenOrderStageModal}
            >
              {t("Order Stages")}
            </Button>
          </Box>
        }
      />
      <Box className={styles.leadBox}>
        <Box className={styles.container2}>
          <Box className={styles.wrapper}>
            <StagesSwitcher
              uniqueStages={job?.stages}
              activeStage={activeStage}
              setActiveStage={setActiveStage}
            />
            <Box className={styles.tableBox}>
              <Grid container direction={"column"}>
                <Table columns={columns} data={filteredData ?? []} />
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        isOpen={openStagingModal}
        onCancel={handleCloseStagingModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          noValidate
          className={styles.modalContent}
          onSubmit={handleStaginCandidate}
        >
          <Typography variant="h4" component="h3">
            {t("changeStageHeader")}
          </Typography>

          <Box sx={{ mb: 15 }}>
            <Select
              options={
                job?.stages.map((stage) => ({
                  label: stage.title,
                  value: stage.id,
                })) as any
              }
              onChange={(option) => setSelectedStage(option as any)}
            />
          </Box>
          <Box className={styles.buttons}>
            <Button type="submit" color="primary" className={styles.add}>
              {t("Change")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                handleCloseStagingModal();
                setSelectedCandidatesIds([]);
              }}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        isOpen={openAddStageModal}
        onCancel={handleCloseAddStageModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          className={styles.modalContent}
          noValidate
          onSubmit={handleAddStage}
        >
          <Typography variant="h4" component="h3">
            {t("addStage")}
          </Typography>
          <TextField
            required
            fullWidth
            onChange={(e) => setAddStageName(e.target.value)}
            value={addStageName}
            label={t("StageName")}
            name="StageName"
          />
          <Box className={styles.buttons}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={styles.add}
            >
              {t("add")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                handleCloseAddStageModal();
              }}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        isOpen={openEditStageModal}
        onCancel={handleCloseEditStageModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          noValidate
          className={styles.modalContent}
          onSubmit={handleEditStage}
        >
          <Typography variant="h4" component="h3">
            {t("EditStage")}
          </Typography>
          <Select
            options={
              job?.stages.map((stage) => {
                return { value: String(stage.id), label: stage.title };
              }) || []
            }
            onChange={(event) => {
              const selectedStageId = parseInt(event.value, 10);
              const selectedStage =
                job?.stages.find((stage) => stage.id === selectedStageId) ??
                null;
              console.log(selectedStage);
              setSelectedEditStage(selectedStage);
            }}
          />

          <TextField
            required
            fullWidth
            onChange={(e) => setEditTitleStage(e.target.value)}
            value={editTitleStage}
            label={t("Title")}
            name="Title"
          />
          <Box className={styles.buttons}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={styles.add}
            >
              {t("Save")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                handleCloseEditStageModal();
              }}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        isOpen={openDeleteStageModal}
        onCancel={handleCloseDeleteStageModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          noValidate
          className={styles.modalContent}
          onSubmit={handleDeleteStage}
        >
          <Typography variant="h4" component="h3">
            {t("DeleteStage")}
          </Typography>
          <Select
            onChange={(event) => {
              const selectedStageId = parseInt(event.value, 10);
              console.log("set selected stage id", selectedStageId);
              const selectedStage =
                job?.stages.find((stage) => stage.id === selectedStageId) ??
                null;
              console.log("selectedStage", selectedStage);
              setSelectedDeleteStage(selectedStage);
            }}
            options={
              job?.stages.map((stage) => {
                return { value: String(stage.id), label: stage.title };
              }) || []
            }
          />
          <Box className={styles.buttons}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={styles.add}
            >
              {t("Delete")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                handleCloseDeleteStageModal();
              }}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        isOpen={openOrderStageModal}
        onCancel={handleCloseDeleteStageModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="form" noValidate onSubmit={handleOrderStages}>
          <Typography textAlign="center" variant="h4" sx={{ mb: 1 }}>
            {t("ChangeStageOrder")}
          </Typography>
          <Table
            columns={orderingStagesColumns}
            data={newOrder}
            enableRowOrdering
            onDragEnd={(e, newOrder) => console.log("onDragEnd", e, newOrder)}
          />
          <Button type="submit" color="primary" variant="contained">
            {t("Save")}
          </Button>
          <Button
            variant="contained"
            color="danger"
            onClick={() => {
              handleCloseOrderStageModal();
            }}
          >
            {t("cancel")}
          </Button>
        </Box>
      </Modal>
    </>
  );
}
