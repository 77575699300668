import { Drawer as DrawerMUI } from '@mui/material';
import React from 'react';

interface DrawerProps {
  openDrawer: boolean;
  setOpenDrawer: (openDrawer: boolean) => void;
  DrawerContent: React.ReactNode;
  anchor: 'top' | 'right' | 'bottom' | 'left';
}

const Drawer: React.FC<DrawerProps> = ({ anchor, DrawerContent, openDrawer, setOpenDrawer }) => {
  return (
    <DrawerMUI anchor={anchor} open={openDrawer} onClose={() => setOpenDrawer(false)}>
      {DrawerContent}
    </DrawerMUI>
  );
};

export default Drawer;
