import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../utils/store";
import { notify } from "./notification";
import { NotificationLevel } from "../types/Notification";
import { AxiosError } from "axios";
import companiesService, {
  CreateCompanyDto,
  UpdateCompanyDto,
} from "../services/companies";
import Company from "../types/Company";
import { initializeCandidates } from "./candidates";
import jobsService from "../services/jobs";
import { UpdateCandidateDto } from "../services/candidates";

const initialState = [] as Company[];

const companiesSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    setCompanies(state, { payload: companies }: PayloadAction<Company[]>) {
      return companies;
    },
    addCompanies(state, { payload: companies }: PayloadAction<Company[]>) {
      return state.concat(companies);
    },
    replaceOne(state, { payload: updatedCompany }: PayloadAction<Company>) {
      return state.map((company) =>
        company.id === updatedCompany.id ? updatedCompany : company
      );
    },
    removeCompany(state, { payload: id }: PayloadAction<number>) {
      return state.filter((company) => company.id !== id);
    },
    clearCompanies(state) {
      return [];
    },
  },
});

export const {
  setCompanies,
  addCompanies,
  replaceOne,
  removeCompany,
  clearCompanies,
} = companiesSlice.actions;

export const initializeCompanies = (me = false) => {
  return async (dispatch: AppDispatch) => {
    try {
      const candidates = me
        ? await companiesService.me()
        : await companiesService.getAll();
      dispatch(setCompanies(candidates));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to fetch companies`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const createCompany = (createCandidateDto: CreateCompanyDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const company = await companiesService.create(createCandidateDto);
      dispatch(
        notify({
          message: "New company was added successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
      dispatch(addCompanies([company]));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to add company. ${
              error.response?.data?.message?.join
                ? error.response?.data?.message?.join(". ")
                : error.response?.data?.message
            }`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const updateCompany = (
  id: number,
  updateCompanyDto: UpdateCompanyDto
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await companiesService.updateOne(id, updateCompanyDto);
      dispatch(
        notify({
          message: "Company was updated successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
      dispatch(initializeCompanies());
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to update company. ${
              error.response?.data?.message?.join
                ? error.response?.data?.message?.join(". ")
                : error.response?.data?.message
            }`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const deleteManyCompanies = (ids: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await companiesService.deleteOne(id);
        dispatch(removeCompany(id));
      }
      dispatch(initializeCandidates());
      dispatch(
        notify({
          message: "Companies are removed successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to remove candidates`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const deleteJobs = (ids: number[], isCompany = true) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await jobsService.remove(id);
      }
      dispatch(initializeCompanies(isCompany));
      dispatch(
        notify({
          message: "jobs are removed successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to remove jobs`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export default companiesSlice.reducer;
