import { Polygon } from "../../../svg";
import styles from "./sidebar.module.scss";

import {
  MailOutlineIcon,
  MediationIcon,
  SearchIcon,
  SelectCursorIcon,
  SettingsIcon,
  ProfileIcon,
} from "../../../svg/icons";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { GrCube } from "react-icons/gr";
import MyImage from "../../UI/MyImage";
import { Tooltip } from "../../UI/Tooltip";
import Linear from "./Linear";
import { useNavigate } from "react-router-dom";

// Get the title of the  href assuming the href is in the format: /title/...
const getTitle = (str: string) => {
  str = str.split("/")[1];
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const Hexagon = ({ active }: { active: boolean }) => {
  return (
    <Box className={`hexagon ${active ? "active" : ""}`}>
      <MyImage src={Polygon} alt="Polygon" className="bg-inherit " />
    </Box>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const links = [
    { id: 0, src: ProfileIcon, alt: "users", href: "/users" },
    {
      id: 1,
      src: SearchIcon,
      href: "/recruiters",
    },
    { id: 2, src: MailOutlineIcon, alt: "jobs", href: "/jobs" },
    { id: 3, src: MediationIcon, alt: "clients", href: "/clients" },
    { id: 4, src: GrCube, alt: "candidates", href: "/candidates" },
    { id: 5, src: SettingsIcon, alt: "Roles", href: "/roles" },
  ];

  return (
    <aside className={styles.aside}>
      <Box className={styles.box}>
        <li className={styles.menu}>Menu</li>
        <Linear />

        <ul className={styles.list}>
          {links.map((icon, index) => (
            <Tooltip
              title={pathname === icon.href ? "" : getTitle(icon.href)}
              placement="right"
            >
              <a
                key={index}
                className="relative"
                onClick={() => {
                  navigate(links[index].href);
                }}
              >
                <motion.div
                  key={icon.id}
                  initial={{ opacity: pathname !== icon.href ? 1 : 0 }}
                  animate={{ opacity: pathname !== icon.href ? 1 : 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <icon.src className={styles.iconSrc} />
                </motion.div>
                {pathname === icon.href && (
                  <Tooltip title={getTitle(icon.href)} placement="right">
                    <motion.div
                      className={styles.motion}
                      layoutId="sidebar-links"
                      transition={{ duration: 0.2, type: "spring" }}
                    >
                      <Box className={styles.animationBox}></Box>
                      <AnimatePresence>
                        <motion.div
                          key={icon.id}
                          initial={{ x: -50, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          exit={{ x: 50, opacity: 0 }}
                          transition={{ delay: 0.2 }}
                        >
                          <Box className="relative ">
                            <Hexagon active={pathname === icon.href} />
                            <icon.src
                              className={styles.iconElement}
                              iconColor="white"
                            />
                          </Box>
                        </motion.div>
                      </AnimatePresence>
                    </motion.div>
                  </Tooltip>
                )}
              </a>
            </Tooltip>
          ))}
          <Linear />
        </ul>
      </Box>
    </aside>
  );
};
export default Sidebar;
