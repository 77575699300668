import { Notification } from "../types/Notification";
import { AppDispatch } from "../utils/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

let timeoutId: NodeJS.Timeout | undefined = undefined;

type NotificationState = Notification | null;

let initialState = null as NotificationState;

const inAccountsSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (
      notification,
      { payload: newNotification }: PayloadAction<Notification>
    ) => {
      return newNotification;
    },
    clearNotification: () => {
      return null;
    },
  },
});

export const { setNotification, clearNotification } = inAccountsSlice.actions;

export const notify = (notification: Notification) => {
  return (dispatch: AppDispatch) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    dispatch(setNotification(notification));
    timeoutId = setTimeout(() => dispatch(clearNotification()), 5000);
  };
};

export default inAccountsSlice.reducer;
