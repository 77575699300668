import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { clearNotification } from "../../reducers/notification";
import { Snackbar, Alert } from "@mui/material";

export default function Notification() {
  const notification = useSelector((state: RootState) => state.notification);
  const dispatch: AppDispatch = useDispatch();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(clearNotification());
  };

  if (!notification) {
    return null;
  }

  return (
    <Snackbar open={Boolean(notification)} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={notification.level}
        sx={{ maxWidth: "100%" }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
}
