import { Job } from "../types/Company";
import axios from "../utils/axios";
import config from "../utils/config";

const baseUrl = `${config.backendUrl}/jobs`;

export type CreateJobDto = {
  title: string;
  description: string;
};

const create = async (createJobDto: CreateJobDto): Promise<Job> => {
  const { data } = await axios.post<Job>(baseUrl, createJobDto);
  return data;
};

const assignJobToClient = async (assignJobToClientDto: {
  emails: string;
  jobId: number;
}): Promise<Job> => {
  const { data } = await axios.post<Job>(
    `${baseUrl}/assignToClient`,
    assignJobToClientDto
  );
  return data;
};

const update = async (id: number, update: CreateJobDto): Promise<Job> => {
  const { data } = await axios.patch<Job>(`${baseUrl}/${id}`, update);
  return data;
};

const remove = async (id: number): Promise<Job> => {
  const { data } = await axios.delete<Job>(`${baseUrl}/${id}`);
  return data;
};

const jobsService = { create, update, remove, assignJobToClient };

export default jobsService;
