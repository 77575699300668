import { Box, Modal as MuiModal } from '@mui/material';
import { ReactNode } from 'react';
interface Props {
  children?: ReactNode;
  isOpen: boolean;
  onCancel?: () => void;
  modalScrollable?: boolean;
}
export default function Modal({ isOpen, onCancel, children, modalScrollable }: Props) {
  const styleLarge = {
    height: '80%',
    width: '60%',
    color: "white",
  };

  const styleSmall = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#111525',
    border: '1px solid #fff',
    boxShadow: 24,
    p: { xs: 2, sm: 4 },
    borderRadius: '10px',
    overflowY: 'scroll',
    width: { xs: 300, sm: 400 },
  };

  return (
    <MuiModal open={isOpen} onClose={onCancel}>
      <Box sx={modalScrollable ? styleLarge : styleSmall}>{children}</Box>
    </MuiModal>
  );
}
