import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

interface TableProps {
  columns: any[];
  data: any[];
  rowSelection?: any;
  onRowSelectionChange?: (rowSelectionState: any) => void;
  enableColumnOrdering?: boolean;
  enableTopToolbar?: boolean;
  enablePagination?: boolean;
  enableBottomToolbar?: boolean;
  enableColumnActions?: boolean;
  enableColumnFilters?: boolean;
  enableSorting?: boolean;
  enableStickyHeader?: boolean;
  enableRowOrdering?: boolean;
  onDragEnd?: any;
}

export default function Table({
  columns,
  data,
  rowSelection,
  onRowSelectionChange = () => {},
  enableColumnOrdering = false,
  enableTopToolbar = false,
  enablePagination = false,
  enableBottomToolbar = false,
  enableColumnActions = false,
  enableColumnFilters = false,
  enableSorting = false,
  enableStickyHeader = true,
  enableRowOrdering = false,
  onDragEnd = () => {},
}: TableProps) {
  const isRowSelection = Boolean(rowSelection);
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowOrdering,
    enableColumnOrdering,
    enableTopToolbar,
    enablePagination,
    enableBottomToolbar,
    enableColumnActions,
    enableColumnFilters,
    enableSorting,
    enableStickyHeader,
    enableRowSelection: isRowSelection,
    getRowId: (row) => row._id,

    onRowSelectionChange,
    state: isRowSelection ? { rowSelection } : {},
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#161B2E",
      baseFontColor: "#FFFFFF",
    }),
    muiRowDragHandleProps: {
      onDragEnd,
    },
    muiTableProps: {
      sx: {
        backgroundColor: "transparent",
        color: "white",
      },
    },

    muiTableBodyCellProps: {
      sx: {
        fontSize: "16px",
        color: "white",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: "10px",
        border: "1px solid #fff",
      },
    },
    muiTableContainerProps: { sx: { minHeight: "100%" } },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "18px",
        color: "white",
        maxHeight: "65vh",
      },
    },
  });

  return <MaterialReactTable table={table} />;
}
