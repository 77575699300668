import MaterialReactTable, {
  type MRT_ColumnDef,
  type MRT_Row,
} from "material-react-table";
import { ChildModal } from "../Candidates/index";
import { ExportToCsv } from "export-to-csv";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout";
import { AppDispatch, RootState } from "../../utils/store";
import { useEffect, useMemo, useState } from "react";
import Modal from "../../components/UI/Modal";
import { Box, Grid, Typography } from "@mui/material";
import Button from "../../components/UI/Button";
import Candidate from "../../types/Candidate";
import Table from "../../components/elements/Table";
import TextField from "../../components/UI/TextField";
import Select from "../../components/UI/Select";
import { deleteJobs, initializeCompanies } from "../../reducers/companies";
import { useNavigate, useParams } from "react-router-dom";
import { notify } from "../../reducers/notification";
import { NotificationLevel } from "../../types/Notification";
import jobsService from "../../services/jobs";
import { useTranslation } from "react-i18next";
import {
  initializeCandidates,
  updateManyCandidates,
} from "../../reducers/candidates";
import ReactSelect from "react-select";
import Company, { Job } from "../../types/Company";
import ActionsBar from "../../components/elements/ActionsBar";

import styles from "./Modal.module.scss";

export default function Client() {
  const [searchTerm, setSearchTerm] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [jobTitle, setJobTitle] = useState<string>("");
  const id = useParams().id;
  const [language, setLanguage] = useState<"EN" | "CZ">("EN");
  const [jobDescription, setJobDescription] = useState<string | null>("");
  const [descriptionfield, setDescription] = useState<string>("");
  const [assignJobToClientEmails, setAssignJobToClientEmails] =
    useState<string>("");
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);
  const [selectedJobIdToAssign, setSelectedJobIdToAssign] = useState<
    number | null
  >(null);
  const [t] = useTranslation(["common"]);
  const { user, companies, AllCandidates } = useSelector(
    (state: RootState) => ({
      user: state.user,
      companies: state.companies,
      AllCandidates: state.candidates,
    })
  );
  const [subject, setSubject] = useState<string>("A new recommended candidate");
  const [message, setMessage] = useState<string>(
    "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>"
  );

  const [editJobTitle, setEditJobTitle] = useState("");
  const [editJobDescription, setEditJobDescription] = useState("");

  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const company = companies.find((company) => company.id === Number(id));
  const [selectedJob, setSelectedJob] = useState<number>();
  const candidates =
    companies
      .find((company) => company.id === Number(id))
      ?.jobs.map(({ title, stages }) =>
        stages.map((stage) => ({
          ...stage,
          candidates: stage.candidates.map((candidate) => ({
            ...candidate,
            stage: {
              id: stage.id,
              title: stage.title,
              job: {
                title,
              },
            },
          })),
        }))
      )
      .flat()
      .map(({ candidates }) => candidates)
      .flat() || [];
  const [selectedCandidates, setSelectedCandidates] = useState<
    { label: string; value: number }[]
  >([]);

  const columns: MRT_ColumnDef<Candidate & { stageText: string }>[] =
    useMemo(() => {
      return [
        {
          accessorKey: "id",
          header: "ID",
        },
        {
          accessorKey: "id",
          header: "Actions",
          muiTableHeadCellProps: {
            align: "center",
          },
          Cell: ({ cell }: any) => {
            return (
              <Box className={styles.actions}>
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedJobId(cell.getValue());
                  }}
                  variant="contained"
                >
                  {t("edit")}
                </Button>
              </Box>
            );
          },
        },
        {
          accessorKey: "id",
          header: "Actions",
          muiTableHeadCellProps: {
            align: "center",
          },
          Cell: ({ cell }: any) => {
            return (
              <Box className={styles.actions}>
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedJobIdToAssign(cell.getValue());
                  }}
                  variant="contained"
                >
                  {t("assignToClient")}
                </Button>
              </Box>
            );
          },
        },
        {
          accessorKey: "title",
          header: "Title",
          muiTableHeadCellProps: {
            align: "left",
          },
          muiTableBodyCellProps: {
            align: "left",
          },
        },
        {
          accessorKey: "description",
          header: "Description",
          Cell: ({ cell }: any) => {
            if (cell.getValue()?.length > 14) {
              return (
                <>
                  <p>
                    {cell.getValue().slice(0, 14)} ...{" "}
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => setJobDescription(cell.getValue())}
                    >
                      More
                    </span>
                  </p>
                </>
              );
            } else {
              return <p>{cell.getValue()}</p>;
            }
          },
          muiTableHeadCellProps: {
            align: "left",
          },
          muiTableBodyCellProps: {
            align: "left",
          },
        },
        {
          accessorKey: "candidates",
          header: "Assigned Candidates",
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: {
            align: "center",
          },
        },
        {
          accessorKey: "id",
          header: "Actions",
          Cell: ({ cell }: any) => {
            return (
              <>
                <Button
                  color="primary"
                  onClick={() => setSelectedJob(cell.getValue())}
                  variant="contained"
                >
                  Assign Candidates
                </Button>
              </>
            );
          },
        },
        {
          accessorKey: "id",
          header: "Actions",
          Cell: ({ cell }: any) => {
            return (
              <>
                <Button
                  color="primary"
                  onClick={() => navigate(`/jobs/${cell.getValue()}`)}
                  variant="contained"
                >
                  Open
                </Button>
              </>
            );
          },
        },
      ] as any;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies]);

  const csvOptions = {
    filename: "jobs",
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(
    null
  );
  const [openCreateModal, setCreateOpenModal] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const handleExportRows = (
    rows: MRT_Row<Candidate & { stageText: string }>[]
  ) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(candidates);
  };

  const handleOpenExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElExport(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorElExport(null);
  };

  const handleOpenCreateModal = () => setCreateOpenModal(true);

  const handleCloseCreateModal = () => setCreateOpenModal(false);

  const handleAddJobSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const title = jobTitle;
    const description = descriptionfield;
    console.log("info of job", title, description);
    if (title && company) {
      const ceateJobtDto = { userId: company.userId, title, description };
      jobsService
        .create(ceateJobtDto as any)
        .then(() => {
          dispatch(
            notify({
              message: "A new job was added successfully",
              level: NotificationLevel.SUCCESS,
            })
          );
          dispatch(initializeCompanies());
        })
        .catch((error) => {
          dispatch(
            notify({
              message: `Failed to add the new job ${(
                error as any
              ).response?.data?.message?.join(". ")}`,
              level: NotificationLevel.ERROR,
            })
          );
        });
      handleCloseCreateModal();
    }
  };

  const handleAssignCandidates = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedJob && company) {
      const stageId = companies
        .find((company) => company.id === Number(company.id))
        ?.jobs.find((job) => job.id === Number(selectedJob))
        ?.stages.find((stage) => stage.order === 1)?.id;

      const email =
        subject.length > 0 && message.length > 0
          ? {
              subject,
              message,
            }
          : undefined;

      const candidateDto = {
        staging: {
          companyId: company.id,
          jobId: selectedJob,
          stageId,
          emailMessage: email,
        },
      };

      dispatch(
        updateManyCandidates(
          selectedCandidates.map(({ value }) => value),
          candidateDto as any
        )
      );
      setSubject("");
      setMessage("");
      setSelectedJob(undefined);
      setSelectedJob(undefined);
      setSelectedCandidates([]);
    }
  };

  const handleEditJobSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const title = editJobTitle;
    const description = editJobDescription;
    console.log("handleEditJobSubmit", editJobTitle, editJobDescription);
    if (title) {
      const updatedJobDto = { title, description } as any;
      try {
        jobsService
          .update(selectedJobId as number, updatedJobDto as any)
          .then(() => {
            dispatch(
              notify({
                message: "A new job was added successfully",
                level: NotificationLevel.SUCCESS,
              })
            );
            dispatch(initializeCompanies());
          });
      } catch (error) {
        dispatch(
          notify({
            message: "Failed to add the new job",
            level: NotificationLevel.ERROR,
          })
        );
      }
      setEditJobDescription("");
      setEditJobTitle("");
      setSelectedJobId(null);
    }
  };

  const handleAssignJobSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("handleAssignJobSubmit", assignJobToClientEmails);
    const emails = assignJobToClientEmails;
    if (emails) {
      const updatedJobDto = { emails, jobId: selectedJobIdToAssign } as any;
      try {
        jobsService.assignJobToClient(updatedJobDto).then(() => {
          dispatch(
            notify({
              message: "The job was assigned successfully",
              level: NotificationLevel.SUCCESS,
            })
          );
          dispatch(initializeCompanies());
        });
      } catch (error) {
        dispatch(
          notify({
            message: "Failed to assign the job",
            level: NotificationLevel.ERROR,
          })
        );
      }
      setSelectedJobIdToAssign(null);
    }
  };

  const handleDeleteJobs = (jobs: Job[]) => {
    dispatch(deleteJobs(jobs.map((job) => job.id)));
  };

  const data =
    (company?.jobs.map((job) => ({
      ...job,
      candidates:
        `(${job.stages.map((stage) => stage.candidates).flat().length}) ` +
        job.stages
          .map((stage) => stage.candidates)
          .flat()
          ?.map((candidate) => `${candidate.firstName} ${candidate.lastName}`)
          .join(", "),
    })) as any) || ([] as any);

  const filteredData = useMemo(() => {
    return data.filter((job) => {
      return job.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [data, searchTerm]);

  useEffect(() => {
    setSubject(
      language === "EN"
        ? "A new recommended candidate"
        : "Nový doporučený kandidát"
    );
    setMessage(
      language === "EN"
        ? "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>"
        : "<h1>Byl přidán kandidát</h1><p><br></p><p>Ahoj,</p><p><br></p><p>{{firstName}} {{lastName}} byl přidán do {{title}}</p>"
    );
  }, [language]);

  return (
    <>
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add User"
        deleteHandler={handleDeleteJobs}
        deleteBtnLabel="Delete Users"
        addHandler={handleOpenCreateModal}
      />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Table columns={columns} data={filteredData} />
        </Grid>
      </Grid>
      <Modal
        isOpen={openCreateModal}
        onCancel={handleCloseCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          className={styles.modalContent}
          noValidate
          onSubmit={handleAddJobSubmit}
        >
          <Typography variant="h4" component="h3">
            Add New Job
          </Typography>
          <TextField
            required
            fullWidth
            label={t("jobTitle")}
            onChange={(e) => setEditJobTitle(e.target.value)}
            value={editJobTitle}
            name="jobTitle"
          />
          <TextField
            required
            fullWidth
            onChange={(e) => setEditJobDescription(e.target.value)}
            value={editJobDescription}
            label={t("Job Description")}
            name="jobDescription"
          />
          <Box className={styles.buttons}>
            <Button type="submit" color="primary" className={styles.add}>
              {t("addNewJob")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => handleCloseCreateModal()}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      {selectedJob && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            noValidate
            className={styles.modalContent}
            onSubmit={handleAssignCandidates}
          >
            <Typography variant="h4" component="h3">
              {t("assignCandidates") +
                ` for ${
                  [company?.jobs?.find((job) => job.id === selectedJob)].map(
                    (job) => ({ label: job?.title, value: job?.id })
                  )[0]?.label
                }`}
            </Typography>
            <Box>
              <Select
                placeholder="Select Candidates"
                isMulti
                options={AllCandidates.filter(({ stage }) => !stage).map(
                  (candidate) => ({
                    label: `${candidate.firstName} ${candidate.lastName}`,
                    value: String(candidate.id),
                  })
                )}
                onChange={(option) => {
                  console.log(option);
                  setSelectedCandidates(option as any);
                }}
              />
            </Box>
            <Box className={styles.buttons}>
              <ChildModal
                subject={subject}
                message={message}
                setSubject={setSubject}
                setMessage={setMessage}
                language={language}
                setLanguage={setLanguage}
              />
              <Button type="submit" color="primary" className={styles.add}>
                {t("assignCandidates")}
              </Button>
              <Button
                variant="contained"
                color="danger"
                onClick={() => {
                  setSelectedJob(undefined);
                  setSubject("");
                  setMessage("");
                }}
              >
                {t("cancel")}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
      {jobDescription && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box component="form" noValidate onSubmit={() => {}}>
            <Typography sx={{ mb: 1 }}>{jobDescription}</Typography>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                setJobDescription(null);
              }}
            >
              close
            </Button>
          </Box>
        </Modal>
      )}
      {selectedJobId && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            noValidate
            className={styles.modalContent}
            onSubmit={handleEditJobSubmit}
          >
            <Typography variant="h4" component="h3">
              Edit Job
            </Typography>
            <TextField
              required
              fullWidth
              label={t("jobTitle")}
              onChange={(e) => setEditJobTitle(e.target.value)}
              value={editJobTitle}
              name="jobTitle"
            />
            <TextField
              required
              fullWidth
              onChange={(e) => setEditJobDescription(e.target.value)}
              value={editJobDescription}
              label={t("Job Description")}
              name="jobDescription"
            />
            <Box className={styles.buttons}>
              <Button type="submit" color="primary" className={styles.add}>
                {t("edit")}
              </Button>
              <Button
                variant="contained"
                color="danger"
                onClick={() => setSelectedJobId(null)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
      {selectedJobIdToAssign && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            noValidate
            className={styles.modalContent}
            onSubmit={handleAssignJobSubmit}
          >
            <Typography variant="h4" component="h3">
              Assign To Client
            </Typography>
            <TextField
              required
              fullWidth
              onChange={(e) => setAssignJobToClientEmails(e.target.value)}
              value={assignJobToClientEmails}
              label={t("emails")}
              name="emails"
            />

            <Box className={styles.buttons}>
              <Button type="submit" color="primary" className={styles.add}>
                {t("edit")}
              </Button>
              <Button
                variant="contained"
                color="danger"
                onClick={() => setSelectedJobIdToAssign(null)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
