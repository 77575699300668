import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../utils/store";
import { notify } from "./notification";
import { NotificationLevel } from "../types/Notification";
import { AxiosError } from "axios";
import roleService from "../services/roles";
import Role, { CreateRoleDto } from "../types/Role";

const initialState = [] as Role[];

const roleSlice = createSlice({
  name: "Roles",
  initialState,
  reducers: {
    setRoles(state, { payload: role }: PayloadAction<Role[]>) {
      return role;
    },
    addRole(state, { payload: role }: PayloadAction<Role>) {
      return state.concat(role);
    },
    replaceOne(state, { payload: role }: PayloadAction<Role>) {
      return state.map((role) => (role.name === role.name ? role : role));
    },
    removeRole(state, { payload: id }: PayloadAction<number>) {
      return state.filter((customUser) => customUser.id !== id);
    },

    clearRoles(state) {
      return [];
    },
  },
});

export const { setRoles, addRole, replaceOne, removeRole, clearRoles } =
  roleSlice.actions;

export const initializeRoles = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const roles = await roleService.getAll();
      dispatch(setRoles(roles));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to fetch Roles`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const createRole = (roleDto: CreateRoleDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const role = await roleService.create(roleDto);
      dispatch(addRole(role));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to add Role.`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const deleteRole = (id: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await roleService.remove(id);
      dispatch(removeRole(id));

      dispatch(
        notify({
          message: "Role removed successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to remove Role`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const deleteManyRoles = (ids: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await roleService.remove(id);
        dispatch(removeRole(id));
      }
      dispatch(
        notify({
          message: "Role removed successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to remove Role`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const updateRole = (role: CreateRoleDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const updatedRole = await roleService.update(role.name, role);
      dispatch(replaceOne(updatedRole));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to update Role`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export default roleSlice.reducer;
