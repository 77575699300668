import { AppDispatch } from "../../utils/store";
import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import { createCandidates } from "../../reducers/candidates";
import { Box, Button, Divider, Modal, Typography } from "@mui/material";

const modalStyle = {
  overflow: "auto",
  height: "100%",
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type columnType =
  | {
      label: "First Name";
      value: { objKey: string; candidateKey: "firstName" };
    }
  | {
      label: "Last Name";
      value: { objKey: string; candidateKey: "lastName" };
    }
  | {
      label: "Email";
      value: { objKey: string; candidateKey: "email" };
    }
  | {
      label: "Linkedin URL";
      value: { objKey: string; candidateKey: "linkedinUrl" };
    }
  | {
      label: "Custom Variable";
      value: { objKey: string; candidateKey: "customVariables" };
    }
  | {
      label: "Do not import";
      value: { objKey: string; candidateKey: null };
    };

interface Props {
  resetNewCandidates: () => void;
  newCandidates: { [key: string]: string }[];
}

export default function AddCandidatesForm({
  newCandidates,
  resetNewCandidates,
}: Props) {
  const dispatch: AppDispatch = useDispatch();
  useState<boolean>(true);
  const [keysMap, setKeysMap] = useState<columnType["value"][]>([]);
  const selectOptions: columnType[] = [
    { label: "First Name", value: { objKey: "", candidateKey: "firstName" } },
    { label: "Last Name", value: { objKey: "", candidateKey: "lastName" } },
    { label: "Email", value: { objKey: "", candidateKey: "email" } },
    {
      label: "Linkedin URL",
      value: { objKey: "", candidateKey: "linkedinUrl" },
    },
    {
      label: "Custom Variable",
      value: { objKey: "", candidateKey: "customVariables" },
    },
    { label: "Do not import", value: { objKey: "", candidateKey: null } },
  ];

  const handleAddCandidatesSubmit = (e: FormEvent) => {
    e.preventDefault();
    const candidatesDtos = newCandidates.map((candidate) => {
      const candidateDto: any = {
        customVariables: {},
      };

      for (const keyMap of keysMap) {
        if (keyMap.candidateKey) {
          if (keyMap.candidateKey !== "customVariables") {
            candidateDto[keyMap.candidateKey] = candidate[keyMap.objKey];
          } else {
            candidateDto.customVariables[keyMap.objKey] =
              candidate[keyMap.objKey];
          }
        }
      }

      return candidateDto;
    });

    const formData = new FormData();
    formData.append("candidateDto", JSON.stringify(candidatesDtos));
    dispatch(createCandidates(formData as any));
    resetNewCandidates();
  };

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={modalStyle}
        component="form"
        noValidate
        onSubmit={handleAddCandidatesSubmit}
      >
        <Typography
          textAlign="center"
          variant="h4"
          sx={{ mb: 5, fontWeight: "800", textDecoration: "underline" }}
        >
          Add New Candidates
        </Typography>

        {newCandidates && newCandidates.length ? (
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  width: { xs: "100%", md: "40%" },
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography textAlign="center" sx={{ width: "40%" }}>
                  Column Name
                </Typography>
                <Typography textAlign="center" sx={{ width: "60%" }}>
                  Select Type
                </Typography>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  width: "60%",
                }}
              >
                <Typography textAlign="center">Samples</Typography>
              </Box>
            </Box>
            <Divider sx={{ mt: 3, mb: 1 }} />
            {Object.keys(newCandidates[0]).map((key, idx) => (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{
                      width: { xs: "100%", md: "40%" },
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography textAlign="center" sx={{ width: "30%" }}>
                      {key}
                    </Typography>
                    <Box sx={{ width: "60%" }}>
                      <ReactSelect
                        options={selectOptions.filter(
                          ({ value: { candidateKey } }) =>
                            !keysMap.find((keyMap) =>
                              keyMap.candidateKey !== "customVariables"
                                ? keyMap.candidateKey === candidateKey
                                : false
                            )
                        )}
                        onChange={(option) => {
                          if (option?.value.candidateKey) {
                            const newKeyMap = {
                              objKey: key,
                              candidateKey: option?.value.candidateKey,
                            };
                            const keysMapWithoutNewKeyMap = keysMap.filter(
                              ({ objKey }) => objKey !== newKeyMap.objKey
                            );
                            setKeysMap([...keysMapWithoutNewKeyMap, newKeyMap]);
                          } else {
                            setKeysMap(
                              keysMap.filter(({ objKey }) => objKey !== key)
                            );
                          }
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "none", md: "block" },
                      width: "60%",
                    }}
                  >
                    <Typography textAlign="center">
                      {newCandidates.slice(0, 4).map((candidate, idx) => (
                        <Typography
                          key={idx}
                          color={
                            idx === 0
                              ? "primary"
                              : idx === 1
                              ? "secondary"
                              : idx === 2
                              ? "gray"
                              : "error"
                          }
                        >
                          {candidate[key]}
                        </Typography>
                      ))}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ mt: 3, mb: 1 }} />
              </>
            ))}
          </Box>
        ) : (
          <p>The given CSV file is empty :(</p>
        )}

        <Button
          type="submit"
          fullWidth
          color="success"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Add Candidates
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="error"
          onClick={() => resetNewCandidates()}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
