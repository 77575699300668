import axios from "../utils/axios";
import config from "../utils/config";
import { Permission } from "../types/Role";
const baseUrl = `${config.backendUrl}/roles`;

const getPermissions = async (): Promise<Permission[]> => {
  const { data } = await axios.get<Permission[]>(
    `${baseUrl}/api/all-permissions`
  );
  return data;
};

const permissionsService = { getPermissions };

export default permissionsService;
