import ReactSelect from "react-select";
import { type MRT_ColumnDef, type MRT_Row } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { useEffect, useMemo, useState } from "react";
import Modal from "../../components/UI/Modal";
import Button from "../../components/UI/Button";
import { Box, Grid, Typography } from "@mui/material";
import TextField from "../../components/UI/TextField";
import Select from "../../components/UI/Select";
import {
  initializeCandidates,
  updateManyCandidates,
} from "../../reducers/candidates";
import {
  createCompany,
  deleteManyCompanies,
  initializeCompanies,
  updateCompany,
} from "../../reducers/companies";
import styles from "./Modal.module.scss";

import Company from "../../types/Company";

import { ChildModal } from "../Candidates/index";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Table from "../../components/elements/Table";
import ActionsBar from "../../components/elements/ActionsBar";

export default function Clients() {
  const [searchTerm, setSearchTerm] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPassword, setCompanyPassword] = useState("");
  const navigate = useNavigate();
  const [language, setLanguage] = useState({ label: "", value: "" });
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  );
  const [t] = useTranslation(["common"]);
  const [subject, setSubject] = useState<string>("A new recommended candidate");
  const [message, setMessage] = useState<string>(
    "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>"
  );
  const { user, candidates, companies } = useSelector((state: RootState) => ({
    user: state.user,
    candidates: state.candidates,
    companies: state.companies,
  }));
  const [editCompanyLanguage, setEditCompanyLanguage] = useState({
    label: "",
    value: "",
  });
  const [editCompanyName, setEditCompanyName] = useState<string>("");
  const [selectedCandidates, setSelectedCandidates] = useState<
    { label: string; value: number }[]
  >([]);
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [selectedJob, setSelectedJob] = useState<{
    label: string;
    value: number;
  }>();
  const columns: MRT_ColumnDef<Company & { stageText: string }>[] = useMemo(
    () =>
      [
        {
          accessorKey: "id",
          header: "ID",
          size: 40,
        },
        {
          accessorKey: "id",
          header: "Actions",
          muiTableHeadCellProps: {
            align: "center",
          },
          Cell: ({ cell }: any) => {
            return (
              <Button
                className="edit"
                color="primary"
                onClick={() => {
                  console.log(cell.getValue());
                  setSelectedCompanyId(cell.getValue());
                }}
                variant="contained"
              >
                {t("edit")}
              </Button>
            );
          },
        },
        {
          accessorKey: "name",
          header: "Name",
          size: 120,
        },
        {
          accessorKey: "language",
          header: "Language",
          size: 120,
        },
        {
          accessorKey: "jobs",
          header: "Jobs",
          Cell: ({ cell }: any) => {
            const Element = cell.getValue().map((one: any) => (
              <Typography sx={{ color: "#fff" }} className={styles.job}>
                {one}
              </Typography>
            ));
            return Element;
          },
        },
        {
          accessorKey: "id",
          header: "Actions",
          Cell: ({ cell }: any) => {
            return (
              <Box className={styles.actions}>
                <Button
                  color="primary"
                  onClick={() => navigate(`/clients/${cell.getValue()}`)}
                  sx={{ mr: 1 }}
                  variant="contained"
                >
                  Open
                </Button>
              </Box>
            );
          },
        },
        {
          accessorKey: "id",
          header: "Actions",
          Cell: ({ cell }: any) => {
            return (
              <Box className={styles.actions}>
                <Button
                  color="primary"
                  onClick={() => setSelectedCompany(cell.getValue())}
                  variant="contained"
                >
                  AssignCandidates
                </Button>
              </Box>
            );
          },
        },
      ] as any,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companies]
  );

  const csvOptions = {
    filename: "companies",
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(
    null
  );
  const [openCreateModal, setCreateOpenModal] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const handleExportRows = (
    rows: MRT_Row<Company & { stageText: string }>[]
  ) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(companies);
  };

  const handleOpenExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElExport(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorElExport(null);
  };

  const handleOpenCreateModal = () => setCreateOpenModal(true);

  const handleCloseCreateModal = () => setCreateOpenModal(false);

  const handleAddCompanySubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const emails = [companyEmail];
    const companyDto = {
      name: companyName || "",
      language: language || "EN",
      users: emails
        ? emails?.map((email) => ({
            email,
            password: companyPassword || "",
          }))
        : [],
    };

    console.log(companyDto);
    dispatch(createCompany(companyDto));
    handleCloseCreateModal();
    setCompanyEmail("");
    setCompanyName("");
    setCompanyPassword("");
    setLanguage({ label: "EN", value: "EN" });
  };

  const handleEditCompanySubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const companyDto = {
      name: editCompanyName || "",
      language: editCompanyLanguage.value || "EN",
    };
    console.log("companyDto", companyDto);
    dispatch(updateCompany(selectedCompanyId as number, companyDto));
    setSelectedCompanyId(null);
    setEditCompanyName("");
    setEditCompanyLanguage({ label: "EN", value: "EN" });
  };

  const handleAssignCandidates = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedCompany && selectedJob) {
      const stageId = companies
        .find((company) => company.id === Number(selectedCompany))
        ?.jobs.find((job) => job.id === Number(selectedJob.value))
        ?.stages.find((stage) => stage.order === 1)?.id;

      const email =
        subject.length > 0 && message.length > 0
          ? {
              subject,
              message,
            }
          : undefined;

      const candidateDto = {
        staging: {
          companyId: selectedCompany,
          jobId: selectedJob.value,
          stageId,
          emailMessage: email,
        },
      };

      dispatch(
        updateManyCandidates(
          selectedCandidates.map(({ value }) => value),
          candidateDto as any
        )
      );
      setSubject("");
      setMessage("");
      setSelectedCompany(undefined);
      setSelectedJob(undefined);
      setSelectedCandidates([]);
    }
  };

  const handleDeleteCompanies = (companies: Company[]) => {
    dispatch(deleteManyCompanies(companies.map((company) => company.id)));
  };

  const data = companies.map((company) => ({
    ...company,
    jobs: company.jobs.map((job) => {
      const jobTitle = job.title;
      const numOfCandidates = job.stages
        .map(({ candidates }) => candidates)
        .flat().length;

      return `${jobTitle}, ${numOfCandidates} candidates`;
    }),
  })) as any;

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [companies, searchTerm]);

  useEffect(() => {
    if (user && companies.length <= 0) {
      dispatch(initializeCandidates());
      dispatch(initializeCompanies());
    }
  }, []);

  useEffect(() => {
    setSubject(
      language.value === "EN"
        ? "A new recommended candidate"
        : "Nový doporučený kandidát"
    );
    setMessage(
      language.value === "EN"
        ? "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>"
        : "<h1>Byl přidán kandidát</h1><p><br></p><p>Ahoj,</p><p><br></p><p>{{firstName}} {{lastName}} byl přidán do {{title}}</p>"
    );
  }, [language]);

  return (
    <>
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add User"
        deleteHandler={handleDeleteCompanies}
        deleteBtnLabel="Delete Users"
        addHandler={handleOpenCreateModal}
      />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Table columns={columns} data={filteredData} />
        </Grid>
      </Grid>
      <Modal
        isOpen={openCreateModal}
        onCancel={handleCloseCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          noValidate
          className={styles.modalContent}
          onSubmit={handleAddCompanySubmit}
        >
          <Typography variant="h4" component="h3">
            Add New Company
          </Typography>
          <TextField
            required
            fullWidth
            onChange={(e) => setCompanyName(e.target.value)}
            value={companyName}
            label={t("companyName")}
            name="companyName"
          />
          <TextField
            required
            fullWidth
            onChange={(e) => setCompanyEmail(e.target.value)}
            value={companyEmail}
            label={t("companyEmail")}
            name="companyEmail"
          />
          <Select
            placeholder="Select Role"
            onChange={(e) => setLanguage(e)}
            isSearchable
            value={language}
            options={[
              { label: "EN", value: "EN" },
              { label: "CZ", value: "CZ" },
            ]}
          />
          <TextField
            required
            fullWidth
            onChange={(e) => setCompanyPassword(e.target.value)}
            value={companyPassword}
            type="password"
            label={t("password")}
            name="companyPassword"
          />
          <Box className={styles.buttons}>
            <Button type="submit" color="primary" className={styles.add}>
              {t("addCompany")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => handleCloseCreateModal()}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
      {selectedCompany && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            className={styles.modalContent}
            noValidate
            onSubmit={handleAssignCandidates}
          >
            <Typography textAlign="center" variant="h4" sx={{ mb: 1 }}>
              {t("assignCandidates")}
            </Typography>
            <Box sx={{ mb: 5, mt: 7 }}>
              <Box sx={{ mb: 3, mt: 3 }}>
                <Select
                  placeholder="Select Job"
                  options={(
                    companies.find((company) => company.id === selectedCompany)
                      ?.jobs || []
                  ).map((job) => ({
                    label: job.title,
                    value: job.id.toString(),
                  }))}
                  onChange={(option) => setSelectedJob(option as any)}
                />
              </Box>
              <Select
                placeholder="Select Candidates"
                isMulti
                options={candidates
                  .filter(({ stage }) => !stage)
                  .map((candidate) => ({
                    label: `${candidate.firstName} ${candidate.lastName}`,
                    value: candidate.id.toString(),
                  }))}
                onChange={(option) => setSelectedCandidates(option as any)}
              />
            </Box>
            <Box className={styles.buttons}>
              <ChildModal
                subject={subject}
                message={message}
                setSubject={setSubject}
                setMessage={setMessage}
                language={language.value}
                setLanguage={setLanguage}
              />
              <Button type="submit" color="primary" variant="contained">
                {t("assignCandidates")}
              </Button>
              <Button
                variant="contained"
                color="danger"
                onClick={() => {
                  setSelectedCompany(undefined);
                  setSelectedJob(undefined);
                  setSubject("");
                  setMessage("");
                }}
              >
                {t("cancel")}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
      {selectedCompanyId !== null && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            noValidate
            className={styles.modalContent}
            onSubmit={handleEditCompanySubmit}
          >
            <Typography variant="h4" component="h3">
              Edit Company
            </Typography>
            <TextField
              required
              fullWidth
              onChange={(e) => setEditCompanyName(e.target.value)}
              value={editCompanyName}
              label={t("companyName")}
              name="companyName"
            />
            <Select
              placeholder="Select language"
              onChange={(e) => setEditCompanyLanguage(e)}
              isSearchable
              value={editCompanyLanguage}
              options={[
                { label: "EN", value: "EN" },
                { label: "CZ", value: "CZ" },
              ]}
            />

            <Box className={styles.buttons}>
              <Button type="submit" color="primary" className={styles.add}>
                {t("edit")}
              </Button>
              <Button
                variant="contained"
                color="danger"
                onClick={() => setSelectedCompanyId(null)}
              >
                {t("cancel")}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
