import axios from "../utils/axios";
import config from "../utils/config";
const baseUrl = `${config.backendUrl}/users/routes`;

const getRoutes = async (): Promise<string[]> => {
    const { data } = await axios.get<string[]>(baseUrl);
    return data;
}

const routesService = {
    getRoutes
}

export default routesService