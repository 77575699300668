import ReactSelect from "react-select";

import { type MRT_ColumnDef, type MRT_Row } from "material-react-table";

import { ExportToCsv } from "export-to-csv";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/UI/Layout";
import { AppDispatch, RootState } from "../../utils/store";
import { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Table from "../../components/elements/Table";
import TextField from "../../components/UI/TextField";

import {
  initializeCandidates,
  updateManyCandidates,
} from "../../reducers/candidates";
import { initializeCompanies, updateCompany } from "../../reducers/companies";
import Button from "../../components/UI/Button";
import { User } from "../../types/User";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/UI/Modal";
import {
  initializeRecruiters,
  addRecruiter,
  removeManyRecruiters,
} from "../../reducers/recruiters";
import ActionsBar from "../../components/elements/ActionsBar";
import styles from "./Modal.module.scss";

export default function Recruiters() {
  const [searchTerm, setSearchTerm] = useState("");
  const [language, setLanguage] = useState<"EN" | "CZ">("EN");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [t] = useTranslation(["common"]);
  const [subject, setSubject] = useState<string>("A new recommended candidate");
  const [message, setMessage] = useState<string>(
    "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>"
  );
  const [rowSelection, setRowSelection] = useState({});

  const { user, companies, recruiters } = useSelector((state: RootState) => ({
    user: state.user,
    candidates: state.candidates,
    companies: state.companies,
    recruiters: state.recruiters,
  }));
  const [selectedCandidates, setSelectedCandidates] = useState<
    { label: string; value: number }[]
  >([]);
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [selectedJob, setSelectedJob] = useState<{
    label: string;
    value: number;
  }>();
  const columns: MRT_ColumnDef<User>[] = useMemo(
    () =>
      [
        {
          accessorKey: "id",
          header: "ID",
          size: 40,
        },

        {
          accessorKey: "email",
          header: "Email",
          size: 120,
        },
      ] as any,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recruiters]
  );

  const filteredRecruiters = useMemo(() => {
    return recruiters.filter((recruiter) => {
      return recruiter.email
        .toLowerCase()
        .includes(searchTerm.toLowerCase().trim());
    });
  }, [searchTerm, recruiters]);

  const csvOptions = {
    filename: "recruiters",
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(
    null
  );
  const [openCreateModal, setCreateOpenModal] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const handleExportRows = (rows: MRT_Row<User>[]) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(recruiters);
  };

  const handleOpenExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElExport(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorElExport(null);
  };

  const handleOpenCreateModal = () => setCreateOpenModal(true);

  const handleCloseCreateModal = () => setCreateOpenModal(false);

  const handleAddRecruiterSubmit = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (email && password) {
      console.log(email, password);
      const recruiterDto = {
        email,
        password,
      };
      dispatch(addRecruiter(recruiterDto));
    }
    handleCloseCreateModal();
  };

  const handleAssignCandidates = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedCompany && selectedJob) {
      const stageId = companies
        .find((company) => company.id === Number(selectedCompany))
        ?.jobs.find((job) => job.id === Number(selectedJob.value))
        ?.stages.find((stage) => stage.order === 1)?.id;

      const email =
        subject.length > 0 && message.length > 0
          ? {
              subject,
              message,
            }
          : undefined;

      const candidateDto = {
        staging: {
          companyId: selectedCompany,
          jobId: selectedJob.value,
          stageId,
          emailMessage: email,
        },
      };

      dispatch(
        updateManyCandidates(
          selectedCandidates.map(({ value }) => value),
          candidateDto as any
        )
      );
      setSubject("");
      setMessage("");
      setSelectedCompany(undefined);
      setSelectedJob(undefined);
      setSelectedCandidates([]);
    }
  };

  const handleDeleteRecruiters = () => {
    const recruiters = Object.keys(rowSelection).map(
      (key) => filteredRecruiters[key]
    );
    dispatch(
      removeManyRecruiters(recruiters.map((recruiters) => recruiters.id))
    );
    setRowSelection({});
  };

  useEffect(() => {
    if (user && companies.length <= 0) {
      dispatch(initializeCandidates());
      dispatch(initializeCompanies());
      dispatch(initializeRecruiters());
    }
  }, []);

  useEffect(() => {
    setSubject(
      language === "EN"
        ? "A new recommended candidate"
        : "Nový doporučený kandidát"
    );
    setMessage(
      language === "EN"
        ? "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>"
        : "<h1>Byl přidán kandidát</h1><p><br></p><p>Ahoj,</p><p><br></p><p>{{firstName}} {{lastName}} byl přidán do {{title}}</p>"
    );
  }, [language]);

  return (
    <>
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add User"
        deleteHandler={handleDeleteRecruiters}
        deleteBtnLabel="Delete Users"
        addHandler={handleOpenCreateModal}
      />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Table
            columns={columns}
            data={filteredRecruiters}
            rowSelection={rowSelection}
            onRowSelectionChange={setRowSelection}
            enableColumnOrdering
            enableColumnActions
          />
        </Grid>
      </Grid>
      <Modal
        isOpen={openCreateModal}
        onCancel={handleCloseCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          noValidate
          className={styles.modalContent}
          onSubmit={handleAddRecruiterSubmit}
        >
          <Typography variant="h4" component="h3">
            Add New Recruiter
          </Typography>
          <TextField
            required
            fullWidth
            label={t("email")}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            name="email"
          />

          <TextField
            required
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            type="password"
            label={t("password")}
            name="password"
          />
          <Box className={styles.buttons}>
            <Button type="submit" color="primary" className={styles.add}>
              {t("addRecruiter")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => handleCloseCreateModal()}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
