import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../utils/store";
import { notify } from "./notification";
import { NotificationLevel } from "../types/Notification";
import { AxiosError } from "axios";
import customUsersService from "../services/customUsers";
import { CreateCustomUserDto, customUser } from "../types/User";

const initialState = [] as customUser[];

const customUsersSlice = createSlice({
  name: "customUsers",
  initialState,
  reducers: {
    setCustomUsers(
      state,
      { payload: customUser }: PayloadAction<customUser[]>
    ) {
      return customUser;
    },
    addCustomUser(state, { payload: customUser }: PayloadAction<customUser>) {
      return state.concat(customUser);
    },
    replaceOne(
      state,
      { payload: updatedCustomUser }: PayloadAction<customUser>
    ) {
      return state.map((updatedCustomUser) =>
        updatedCustomUser.id === updatedCustomUser.id
          ? updatedCustomUser
          : updatedCustomUser
      );
    },
    removeCustomUser(state, { payload: id }: PayloadAction<number>) {
      return state.filter((customUser) => customUser.id !== id);
    },
    clearCustomUsers(state) {
      return [];
    },
  },
});

export const {
  setCustomUsers,
  addCustomUser,
  replaceOne,
  removeCustomUser,
  clearCustomUsers,
} = customUsersSlice.actions;

export const initializeCustomUsers = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const candidates = await customUsersService.getAll();
      dispatch(setCustomUsers(candidates));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to fetch customUsers`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const createOneCustomUser = (
  createCustomUserDto: CreateCustomUserDto
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const customUser = await customUsersService.addOne(createCustomUserDto);
      dispatch(addCustomUser(customUser));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to add customUser. ${error.response?.data?.message?.join(
              ". "
            )}`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const deleteCustomUser = (id: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await customUsersService.deleteOne(id);
      dispatch(removeCustomUser(id));

      dispatch(
        notify({
          message: "User removed successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to remove User`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const deleteManyCustomUsers = (ids: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await customUsersService.deleteOne(id);
        dispatch(removeCustomUser(id));
      }
      dispatch(
        notify({
          message: "Users removed successfully",
          level: NotificationLevel.SUCCESS,
        })
      );
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          notify({
            message: `Failed to remove Users`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export default customUsersSlice.reducer;
