import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  listItemClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";

//import { usePathname, useRouter } from "next/navigation";
import { useMemo, useState } from "react";
import Button from "../Button";
import Drawer from "../Drawer";
import { TreeChild } from "./NavLinks.types";
import styles from "./navbar.module.scss";

const campaignDrawerData = [
  {
    title: "Leads",
    path: "/leads",
  },
  {
    title: "Sequence",
    path: "/sequence",
  },
  {
    title: "Analytics",
    path: "/analytics",
  },
  {
    title: "Schedule",
    path: "/schedule",
  },
  {
    title: "Options",
    path: "/options",
  },
];

const DrawerContent = ({ close }: { close: () => void }) => {
  //const router = useRouter();
  //const path = usePathname();
  const location = useLocation();
  const path = location.pathname;
  const paths = path?.slice(1)?.split("/");

  const currentPath = paths?.slice(0, -1).join("/");

  return (
    <List
      sx={{
        width: "75vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          // gap: '1rem',
          padding: "2rem",
        }}
      >
        {campaignDrawerData.map((item) => (
          <ListItem
            sx={[
              {
                [`&.${listItemClasses.root}`]: {
                  transition: "all 0.3s",
                  p: "0",
                },
                [`&.${listItemClasses.root}:hover`]: {
                  bgcolor: "primary.light",
                  color: "black",
                },
              },
              { borderRadius: "1rem" },
              path.includes(item.title.toLocaleLowerCase())
                ? { bgcolor: "primary.dark" }
                : {},
            ]}
            key={item.title}
          >
            <ListItemButton
              onClick={() => {
                close();
                //       router.push(`/${currentPath}/${item.path}`);
              }}
            >
              <ListItemText
                primary={item.title}
                sx={{ width: "100%", textAlign: "center" }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </Box>
    </List>
  );
};

const Navbar = () => {
  const them = useTheme();
  const matchesBelowMd = useMediaQuery(them.breakpoints.down("md"));
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  const paths = pathname?.slice(1)?.split("/");
  const tree: TreeChild[] = [];

  const showMobileNavbarBtn = useMemo(
    () =>
      paths?.includes("campaigns") &&
      paths.at(-1) !== "campaigns" &&
      matchesBelowMd,
    [paths, matchesBelowMd]
  );

  if (!pathname) return <></>;

  switch (paths.length) {
    case 1:
      tree.push({
        title: paths[0],
        path: `/${paths[0]}`,
      });
      break;
    case 2:
      tree.push({
        title: "my " + paths[0].slice(0, paths[0].length - 1),
        path: `/${paths[0]}/${paths[1]}`,
      });
      break;
    case 3:
      tree.push(
        {
          title: "my " + paths[0].slice(0, paths[0].length - 1),
          path: `/${paths[0]}/${paths[1]}`,
        },
        { title: paths[2], path: `/${paths[0]}/${paths[1]}/${paths[2]}` }
      );
      break;
    default:
      tree.push({ title: "AutoLead", path: "/" });
  }

  return (
    <>
      <nav className={styles.nav}>
        <Box className={styles.box}>
          {paths.length > 1 && (
            <Button className={styles.prevBtn}>
              <a href={`/${paths[0]}`}>
                <ArrowBackIosNewIcon />
              </a>
            </Button>
          )}

          <Box className={styles.topNavbar}>
            <Box className={styles.links}>
              {tree.map((t, ind) => {
                if (ind + 1 < tree.length) {
                  return (
                    <>
                      <Box key={ind} className={styles.link}>
                        {t.title}
                      </Box>
                      <Typography>/</Typography>
                    </>
                  );
                }
                return (
                  <Box key={ind} className={styles.link}>
                    {t.title}
                  </Box>
                );
              })}
            </Box>
            {showMobileNavbarBtn && (
              <Button
                type="submit"
                onClick={() => setOpenDrawer((open) => !open)}
                sx={openDrawer ? {} : { bgcolor: "transparent" }}
              ></Button>
            )}
          </Box>
        </Box>
      </nav>

      <Drawer
        anchor="left"
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        DrawerContent={<DrawerContent close={() => setOpenDrawer(false)} />}
      ></Drawer>
    </>
  );
};

export default Navbar;
