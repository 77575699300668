import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, CreateUserDto } from "../types/User";
import recruitersService from "../services/recruiters";
import { AppDispatch } from "../utils/store";
import { notify } from "./notification";
import { NotificationLevel } from "../types/Notification";
import { AxiosError } from "axios";

const initialState = [] as User[];

export const recruitersSlice = createSlice({
  name: "recruiters",
  initialState,
  reducers: {
    setRecruiters(state, { payload: users }: PayloadAction<User[]>) {
      return users;
    },
    addUser(state, { payload: user }: PayloadAction<User>) {
      return state.concat(user);
    },
    removeUser(state, { payload: id }: PayloadAction<number>) {
      return state.filter((user) => user.id !== id);
    },
  },
});

export const { setRecruiters, addUser, removeUser } = recruitersSlice.actions;

export const initializeRecruiters = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const users = await recruitersService.getAll();
      dispatch(setRecruiters(users));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.log("test", error);
        dispatch(
          notify({
            message: `Failed to fetch recruiters`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export type CreateRecruiter = {
  name: string;
};

export const addRecruiter = (recruiter: CreateUserDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const user = await recruitersService.addOne(recruiter);
      dispatch(addUser(user));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.log("test", error);
        dispatch(
          notify({
            message: `Failed to add recruiter`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const removeRecruiter = (id: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await recruitersService.deleteOne(id);
      dispatch(removeUser(id));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.log("test", error);
        dispatch(
          notify({
            message: `Failed to remove recruiter`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export const removeManyRecruiters = (ids: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await recruitersService.deleteOne(id);
        dispatch(removeUser(id));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.log("test", error);
        dispatch(
          notify({
            message: `Failed to remove recruiters`,
            level: NotificationLevel.ERROR,
          })
        );
      }
    }
  };
};

export default recruitersSlice.reducer;
