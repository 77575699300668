import { CSSProperties } from "react";
import styles from "./image.module.scss";

interface MyImageProps {
  useNextImage?: boolean;
  src: string;
  alt: string;
  isAvatar?: boolean;
  className?: string;
  style?: CSSProperties;
  isModal?: boolean;
}

const MyImage: React.FC<MyImageProps> = ({
  useNextImage = true,
  src,
  alt,
  isAvatar = false,
  isModal = false,
  className = "",
  style = {},
}) => {
  const imageClass = isAvatar
    ? `${styles.avatar} ${className}`
    : isModal
    ? `${styles.image} ${className}`
    : className;

  if (useNextImage) {
    return <img src={src} alt={alt} className={imageClass} style={style} />;
  } else {
    return <img src={src} alt={alt} className={imageClass} style={style} />;
  }
};

export default MyImage;
