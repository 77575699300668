import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Candidate from "../../types/Candidate";
import candidateService from "../../services/candidates";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

export default function CandidatesPage() {
  const id = useParams().id;
  const [candidates, setCandidates] = useState<Candidate[]>([]);

  const initializeCandidates = async (page: string) => {
    const candidates = await candidateService.getPageCandidates(page);
    setCandidates(candidates);
  };

  useEffect(() => {
    if (id) {
      initializeCandidates(id);
    }
  }, [id]);

  return (
    <Box
      sx={{
        background: "#EEE",
        width: "100%",
        minHeight: "100vh",
      }}
    >
      <Typography variant="h3" textAlign="center" pt={2}>
        {id?.toUpperCase()} Candidates
      </Typography>
      <Box
        sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", padding: 5 }}
      >
        {candidates.map((candidate) => (
          <Card
            sx={{
              maxWidth: 345,
              marginBottom: 5,
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="100%"
                image="https://xsgames.co/randomusers/assets/avatars/male/63.jpg"
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {candidate.firstName} {candidate.lastName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Lizards are a widespread group of squamate reptiles, with over
                  6,000 species, ranging across all continents except Antarctica
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
    </Box>
  );
}
