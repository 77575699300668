import { Tooltip as MuiTooltip} from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip';


export const Tooltip = (props: TooltipProps) => (
  <MuiTooltip
    arrow
    {...props}
    componentsProps={{
      tooltip: { sx: { backgroundColor: 'black', fontSize: 14, padding: '10px 15px' } },
      arrow: { sx: { color: 'black' } },
    }}
  />
);
