import React, { useState } from "react";
import { Job, Stage } from "../../types/Company";
import Company from "../../types/Company";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import { NotificationLevel } from "../../types/Notification";
import { deleteJobs, initializeCompanies } from "../../reducers/companies";
import { AppDispatch, RootState } from "../../utils/store";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { t } from "i18next";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Modal,
  TextField,
} from "@mui/material";
import jobsService from "../../services/jobs";
import { notify } from "../../reducers/notification";
import Button from "../UI/Button";

interface CompanyProps {
  company: Company;
}
const modalStyle = {
  overflow: "auto",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const JobComponent: React.FC<{ job: Job }> = ({ job }) => {
  const navigate = useNavigate();
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);
  const [selectedJobIdToAssign, setSelectedJobIdToAssign] = useState<
    number | null
  >(null);
  const [openCreateModal, setCreateOpenModal] = useState(false);

  const [selectedJobToDelete, setSelectedJobToDelete] = useState<number | null>(
    null
  );
  const dispatch: AppDispatch = useDispatch();
  const { user, companies } = useSelector((state: RootState) => ({
    user: state.user,
    companies: state.companies,
  }));

  const handleEditJobSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    console.log("client", selectedJobId);
    const data = new FormData(event.currentTarget);
    const title = data.get("jobTitle")?.toString();
    const description = data.get("jobDescription")?.toString();
    if (title) {
      const updatedJobDto = { title, description } as any;
      try {
        jobsService
          .update(selectedJobId as number, updatedJobDto as any)
          .then(() => {
            dispatch(
              notify({
                message: "A new job was added successfully",
                level: NotificationLevel.SUCCESS,
              })
            );
            dispatch(initializeCompanies(true));
          });
      } catch (error) {
        dispatch(
          notify({
            message: "Failed to add the new job",
            level: NotificationLevel.ERROR,
          })
        );
      }
      setSelectedJobId(null);
    }
  };

  return (
    <Box my={2}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
        gap={2}
      >
        <Typography
          maxWidth={250}
          minWidth={250}
          maxHeight={50}
          noWrap
          variant="h5"
        >
          {job.title}
        </Typography>
        <Button
          sx={{ height: "fit-content" }}
          size="small"
          color="primary"
          onClick={() => navigate(`/jobs/${job.id}`)}
          variant="contained"
        >
          {t("open")}
        </Button>
        {((user as any)?.isClientOwner || user?.role === "admin") && (
          <Button
            sx={{ height: "fit-content" }}
            size="small"
            color="primary"
            onClick={() => {
              setSelectedJobIdToAssign(job.id);
            }}
            variant="contained"
          >
            {t("assignToClient")}
          </Button>
        )}

        <Button
          color="primary"
          sx={{ height: "fit-content" }}
          size="small"
          onClick={() => {
            setSelectedJobId(job.id);
          }}
          variant="contained"
        >
          {t("edit")}
        </Button>
        <Button
          color="danger"
          onClick={() => {
            setSelectedJobToDelete(job.id);
          }}
          startIcon={<DeleteForeverIcon />}
          variant="contained"
        >
          {t("deleteSelectedJobs")}
        </Button>
      </Box>
      <Box
        display={"inline-flex"}
        gap={10}
        sx={{ width: "100%", justifyContent: "space-around" }}
      >
        {job.stages
          .slice()
          .sort((a, b) => a.order - b.order)
          .map((stage) => (
            <Box
              key={stage.order}
              my={4}
              display="flex"
              alignItems="center"
              gap={4}
              p={2}
            >
              <Box alignItems={"center"} justifyContent={"center"}>
                <Typography variant="h6" textOverflow={"ellipsis"}>
                  {stage.title.toLocaleUpperCase()}
                </Typography>
                <Typography variant="h5" textAlign={"center"} marginY={2}>
                  {stage.candidates.length}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>

      {selectedJobId && (
        <Modal
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={modalStyle}
            component="form"
            noValidate
            onSubmit={handleEditJobSubmit}
          >
            <Typography textAlign="center" variant="h4" sx={{ mb: 1 }}>
              Edit Job
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              defaultValue={job.title || ""}
              id="jobTitle"
              label={t("jobTitle")}
              name="jobTitle"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              defaultValue={job.description || ""}
              id="jobDescription"
              label={t("Job Description")}
              name="jobDescription"
            />

            <Button
              type="submit"
              color="primary"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("edit")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => setSelectedJobId(null)}
            >
              Cancel
            </Button>
          </Box>
        </Modal>
      )}
      {selectedJobIdToAssign && (
        <Modal
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} component="form" noValidate>
            <Typography textAlign="center" variant="h4" sx={{ mb: 1 }}>
              Assign To Client
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="emails"
              label={t("emails")}
              name="emails"
            />

            <Button
              type="submit"
              color="primary"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("edit")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => setSelectedJobIdToAssign(null)}
            >
              Cancel
            </Button>
          </Box>
        </Modal>
      )}
      {selectedJobToDelete && (
        <Modal
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} component="form" noValidate onSubmit={() => {}}>
            <Typography textAlign="center" variant="h4" sx={{ mb: 1 }}>
              You Sure you want to Delete ?
            </Typography>

            <Button
              onClick={() => dispatch(deleteJobs([job.id], true))}
              color="danger"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("Delete")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSelectedJobToDelete(null)}
            >
              Cancel
            </Button>
          </Box>
        </Modal>
      )}

      <Divider />
    </Box>
  );
};

const CompanyComponent: React.FC<CompanyProps> = ({ company }) => {
  const [openCreateModal, setCreateOpenModal] = useState(false);
  const handleCloseCreateModal = () => setCreateOpenModal(false);
  const handleOpenCreateModal = () => setCreateOpenModal(true);

  const dispatch: AppDispatch = useDispatch();

  const handleAddJobSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const title = data.get("jobTitle")?.toString();
    const description = data.get("jobDescription")?.toString();
    if (title) {
      const ceateJobtDto = { title, description };
      try {
        jobsService.create(ceateJobtDto as any).then(() => {
          dispatch(
            notify({
              message: "A new job was added successfully",
              level: NotificationLevel.SUCCESS,
            })
          );
          dispatch(initializeCompanies(true));
        });
      } catch (error) {
        dispatch(
          notify({
            message: "Failed to add the new job",
            level: NotificationLevel.ERROR,
          })
        );
      }
      handleCloseCreateModal();
    }
  };
  return (
    <Box
      p={2}
      display={"flex"}
      alignItems={"flex-start"}
      flexDirection={"column"}
      gap={5}
    >
      <Box>
        <Typography variant="h3">{company.name}</Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setCreateOpenModal(true)}
          sx={{ mt: 3, mb: 2 }}
        >
          {t("addNewJob")}
        </Button>
      </Box>
      <Grid container direction="column" spacing={1} mx={2}>
        {company.jobs.map((job) => (
          <JobComponent key={job.id} job={job} />
        ))}
      </Grid>
      <Modal
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={modalStyle}
          component="form"
          noValidate
          onSubmit={handleAddJobSubmit}
        >
          <Typography textAlign="center" variant="h4" sx={{ mb: 1 }}>
            Add New Job
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="jobTitle"
            label={t("jobTitle")}
            name="jobTitle"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="jobDescription"
            label={t("Job Description")}
            name="jobDescription"
          />

          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("addNewJob")}
          </Button>
          <Button
            variant="contained"
            color="danger"
            onClick={() => handleCloseCreateModal()}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default CompanyComponent;
