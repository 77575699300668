import { Box } from "@mui/material";
import Sidebar from "../../elements/Sidebar";
import Container from "../Container";
import Navbar from "../Navbar";

export type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <Box sx={{ display: "flex", height: "100vh", width: "100vw" }}>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: "100%",
            backgroundColor: "primary",
          }}
        >
          <Navbar />
          <Container>{children}</Container>
        </Box>
      </Box>
    </Box>
  );
};
export default Layout;
