import Button from '../../UI/Button';
import { useTheme } from '@emotion/react';
import { Box, InputBaseComponentProps, TextField as MuiTextField, SxProps, Theme } from '@mui/material';
import React from 'react';

export interface TextFieldProps {
  withButton?: boolean;
  label: string;
  children?: React.ReactNode;
  value?: string;
  buttonOnClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  sx?: SxProps;
  type?: string;
  buttonType?: 'button' | 'reset' | 'submit';
  required?: boolean;
  error?: boolean;
  helperText?: string;
  name?: string;
  validations?: any;
  register?: any;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  fullWidth?: any,
  variant?: string;
  icon?: React.ReactElement;
  inputProps?: InputBaseComponentProps;
}
const TextField = ({
  buttonOnClick,
  withButton,
  children,
  sx,
  buttonType = 'button',
  register = false,
  name,
  validations,
  label,
  icon,
  ...props
}: TextFieldProps) => {
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', width: '100%', alignItems: icon && 'end', gap: icon && '10px' }}>
      {icon && <>{icon}</>}

      <MuiTextField
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'secondary.main',
            '& fieldset': {
              borderColor: '#fff',
              borderRight: withButton ? 'none' : '1px solid',
              borderRadius: withButton ? '0' : '10px',
            },
            '&:hover fieldset': {
              borderColor: 'primary.light',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.light',
            },
            '&::before': {
              borderBottom: '1px solid #fff',
            },
          },
          '& .MuiInput-root': {
            '&::before': {
              borderColor: '#fff',
            },
            '&.Mui-focused': {
              borderColor: '#fff',
            },
            '&:hover': {
              borderColor: '#fff',
            },
            '&::after': {
              borderColor: (theme as Theme).palette.primary.light,
            },
            ':hover:not(.Mui-focused)': {
              '&:before': {
                borderColor: '#fff',
              },
            },
          },
          '& .MuiInputLabel-standard': {
            color: '#fff !important',
            '&.Mui-focused': {
              color: `${(theme as Theme).palette.primary.light} !important`,
            },
          },
          width: withButton ? '80%' : '100%',
          ...sx,
        }}
        InputLabelProps={{
          style: {
            color: (theme as Theme).palette.grey[500],
          },
        }}
        InputProps={{ sx: { borderRadius: '10px 0 0 10px', color: '#fff' } }}
        variant="outlined"
        label={label}
        {...(register && register(name, validations))}
        {...props}
      />

      {withButton && (
        <Box sx={{ width: '20%' }}>
          <Button
            type={buttonType}
            sx={{
              transition: 'all 0s ease-in-out',
              width: '100%',
              borderRadius: '0 10px 10px 0',
              border: '1px solid',
              borderColor: isHovered || isFocused ? 'primary.light' : '#fff',
              borderLeft: 'none',
              height: '100%',
            }}
            onClick={buttonOnClick}
          >
            {children}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TextField;
