import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "../../components/UI/Button";
import TextField from "../../components/UI/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { login } from "../../reducers/user";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Login.module.scss";

export default function SignIn() {
  const [t] = useTranslation(["common"]);
  const user = useSelector((state: RootState) => state.user);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const credentials = {
      email: email,
      password: password,
    };
    dispatch(login(credentials /*remember*/));
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [navigate, user]);

  if (user) {
    return null;
  }

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            "url(https://media.istockphoto.com/id/1384110533/photo/asian-male-director-is-interviewing-to-recruit-new-employees.jpg?b=1&s=170667a&w=0&k=20&c=RKlwV1dxFayGWKLj3eu9PwNekIzQwQBtRkEjHVEEnYI=)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 0.8,
        }}
      />
      <Grid
        item
        sx={{ bgcolor: "#111525" }}
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
      >
        <Box className={styles.container}>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("signIn")}
          </Typography>
          <Box
            component="form"
            noValidate
            className={styles.form}
            onSubmit={handleSubmit}
          >
            <TextField
              required
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              label={t("email")}
              name="email"
            />
            <TextField
              required
              fullWidth
              name="password"
              label={t("password")}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button type="submit" className={styles.button} variant="contained">
              Sign In
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
