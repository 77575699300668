import { Routes, Route, useNavigate } from "react-router-dom";
import routes from "./pages/routes";
import Notification from "./components/Notification";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUser, logout } from "./reducers/user";
import { UserWithToken } from "./services/auth";
import { setToken } from "./utils/axios";
import { AppDispatch, RootState } from "./utils/store";
import "./styles/globals.scss";
import Layout from "./components/UI/Layout";

function App() {
  const user = useSelector((state: RootState) => state.user);
  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const userWithToken: UserWithToken = JSON.parse(auth);
      dispatch(addUser(userWithToken.user));
      setToken(userWithToken.accessToken);
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  return (
    <>
      <Routes>
        {routes.map(({ url, component: Component }) => (
          <Route
            key={url}
            path={url}
            element={
              url === "/login" ? (
                <Component />
              ) : (
                <Layout>
                  <Component />
                </Layout>
              )
            }
          />
        ))}
      </Routes>
      <Notification />
    </>
  );
}

export default App;
