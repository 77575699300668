import ReactSelect from "react-select";
import MaterialReactTable, {
  type MRT_ColumnDef,
  type MRT_Row,
} from "material-react-table";
import { CreateRoleDto, PermissionName } from "../../types/Role";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ExportToCsv } from "export-to-csv";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { useEffect, useMemo, useState } from "react";
import { createRole } from "../../reducers/role";
import { ThemeProvider, createTheme, useTheme } from "@mui/material";

import { Box, Grid, Typography } from "@mui/material";
import Modal from "../../components/UI/Modal";
import Table from "../../components/elements/Table";
import TextField from "../../components/UI/TextField";
import CheckBox from "../../components/UI/CheckBox";
import Button from "../../components/UI/Button";
import {
  initializeCustomUsers,
  createOneCustomUser,
  deleteCustomUser,
  deleteManyCustomUsers,
} from "../../reducers/customUsers";
import { updateCompany } from "../../reducers/companies";
import { CreateCustomUserDto, UserRole, customUser } from "../../types/User";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { initializeRoles, updateRole } from "../../reducers/role";
import Role, { Permission } from "../../types/Role";
import { initializePermissions } from "../../reducers/permissions";

import styles from "./Modal.module.scss";
import ActionsBar from "../../components/elements/ActionsBar";

export default function Roles() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [language, setLanguage] = useState<"EN" | "CZ">("EN");
  const [selectedRoleName, setSelectedRoleName] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [addRoleName, setAddRoleName] = useState<string | null>(null);

  const [t] = useTranslation(["common"]);
  const [subject, setSubject] = useState<string>("A new recommended candidate");
  const [message, setMessage] = useState<string>(
    "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>"
  );
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    []
  );
  const { user, roles, permissions } = useSelector((state: RootState) => ({
    user: state.user,
    roles: state.roles,
    permissions: state.permissions,
  }));

  const columns: MRT_ColumnDef<Role>[] = useMemo(
    () =>
      [
        {
          accessorKey: "name",
          header: "NAME",
          size: 120,
        },
        {
          accessorKey: "permissions",
          header: "PERMISSIONS",
          size: 200, // Adjust the size as needed
          Cell: ({ cell }: { cell: { value: Permission[] } }) => (
            <Box>
              {cell.value?.map((permission) => (
                <span key={permission.id}>{permission.name}</span>
              ))}
            </Box>
          ),
        },
        {
          accessorKey: "name",
          header: "Actions",
          muiTableHeadCellProps: {
            align: "center",
          },
          Cell: ({ cell }: any) => {
            return (
              <Box>
                <Button
                  color="primary"
                  onClick={() => {
                    console.log(selectedPermissions);
                    setSelectedRoleName(cell.getValue());
                  }}
                  variant="contained"
                  sx={{ mr: 2 }}
                >
                  {t("editPermissions")}
                </Button>
              </Box>
            );
          },
        },
      ] as any,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roles]
  );

  const csvOptions = {
    filename: "Roles",
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(
    null
  );
  const [openCreateModal, setCreateOpenModal] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const handleExportRows = (rows: MRT_Row<Role>[]) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handlePermissionToggle = (permission: Permission) => {
    if (
      selectedPermissions.some(
        (p) => p.id === permission.id && p.name === permission.name
      )
    ) {
      // Remove the permission if it's already checked
      setSelectedPermissions((prev) =>
        prev.filter(
          (p) => !(p.id === permission.id && p.name === permission.name)
        )
      );
    } else {
      // Add the permission if it's not checked
      setSelectedPermissions((prev) => [...prev, permission]);
    }
  };
  const handleExportData = () => {
    csvExporter.generateCsv(roles);
  };

  const handleOpenExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElExport(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorElExport(null);
  };

  const handleOpenCreateModal = () => setCreateOpenModal(true);

  const handleCloseCreateModal = () => setCreateOpenModal(false);

  const handleAddRoleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = addRoleName;

    if (name) {
      const role: CreateRoleDto = {
        name,
        permissions: selectedPermissions,
      };
      dispatch(createRole(role));
    }
    setSelectedPermissions([]);
    handleCloseCreateModal();
  };

  const handleEditRolePermissions = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = selectedRoleName;

    if (name) {
      const role: CreateRoleDto = {
        name,
        permissions: selectedPermissions,
      };
      dispatch(updateRole(role));
    }
    setSelectedPermissions([]);
    setSelectedRoleName(null);
    handleCloseCreateModal();
  };
  const handleDeleteRoles = (customUsers: customUser[]) => {
    dispatch(
      deleteManyCustomUsers(customUsers.map((customUser) => customUser.id))
    );
  };

  useEffect(() => {
    if (user) {
      dispatch(initializeCustomUsers());
      dispatch(initializeRoles());
      dispatch(initializePermissions());
    }
  }, []);

  useEffect(() => {
    const rolesSelectedPermissions = roles.find(
      (role) => role.name === selectedRoleName
    )?.permissions;
    setSelectedPermissions(rolesSelectedPermissions ?? []);
    console.log("rolesSelectedPermissions", rolesSelectedPermissions);
  }, [selectedRoleName]);

  useEffect(() => {
    setSubject(
      language === "EN"
        ? "A new recommended candidate"
        : "Nový doporučený kandidát"
    );
    setMessage(
      language === "EN"
        ? "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>"
        : "<h1>Byl přidán kandidát</h1><p><br></p><p>Ahoj,</p><p><br></p><p>{{firstName}} {{lastName}} byl přidán do {{title}}</p>"
    );
  }, [language]);

  const filteredRoles = useMemo(() => {
    return roles.filter((role) =>
      role.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [roles, searchTerm]);

  return (
    <>
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add Role"
        deleteHandler={handleDeleteRoles}
        deleteBtnLabel="Delete Roles"
        addHandler={handleOpenCreateModal}
      />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Table
            columns={columns}
            data={filteredRoles}
            onRowSelectionChange={setRowSelection}
            rowSelection={rowSelection}
          />
        </Grid>
      </Grid>
      <Modal
        isOpen={openCreateModal}
        onCancel={handleCloseCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          className={styles.modalContent}
          noValidate
          onSubmit={handleAddRoleSubmit}
        >
          <Typography variant="h4" component="h3">
            Add New Role
          </Typography>
          <TextField
            required
            fullWidth
            label={t("name")}
            onChange={(e) => setAddRoleName(e.target.value)}
            name="name"
          />
          {permissions.map((permission) => (
            <CheckBox
              key={permission.name} // Ensure to provide a unique key
              defaultChecked={false}
              label={permission.name}
              isChecked={selectedPermissions.some(
                (p) => p.id === permission.id && p.name === permission.name
              )}
              onChange={() => handlePermissionToggle(permission)}
            />
          ))}
          <Box className={styles.buttons}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={styles.add}
            >
              {t("addRole")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                handleCloseCreateModal();
              }}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
      {selectedRoleName !== null && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            noValidate
            className={styles.modalContent}
            onSubmit={handleEditRolePermissions}
          >
            <Typography variant="h4" component="h3">
              Edit Permissions
            </Typography>
            {permissions.map((permission) => (
              <CheckBox
                key={permission.name} // Ensure to provide a unique key
                label={permission.name}
                isChecked={selectedPermissions.some(
                  (p) => p.id === permission.id && p.name === permission.name
                )}
                onChange={() =>
                  handlePermissionToggle({
                    id: permission.id,
                    name: permission.name,
                  })
                }
              />
            ))}
            <Box className={styles.buttons}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={styles.add}
              >
                {t("edit")}
              </Button>
              <Button
                variant="contained"
                color="danger"
                onClick={() => {
                  setSelectedRoleName(null);
                }}
              >
                {t("cancel")}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
