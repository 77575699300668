import { Email, LinkedIn, Phone, UploadFile } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Candidate from "../../types/Candidate";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { grey, lightBlue, teal } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
const modalStyle = {
  overflow: "auto",
  position: "absolute" as "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  width: "70%",
  bgcolor: grey["100"],
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  transition: "opacity .7s ease-in-out",
  opacity: 0,
};

const modalVisibleStyle = {
  ...modalStyle,
  opacity: 1,
};

interface CandidateProfileProps {
  candidateId?: number;
  close?: () => void;
}
const CandidateProfile: React.FC<CandidateProfileProps> = ({
  candidateId,
  close,
}) => {
  const { user, candidates, companies } = useSelector((state: RootState) => ({
    user: state.user,
    candidates: state.candidates,
    companies: state.companies,
  }));
  const candidate = candidates.find((c) => c.id === candidateId);
  const candidateCompany = companies.find((c) => c.id === candidate?.companyId);
  const candidateJob = candidate?.stage?.job;

  const capitalizeFirstLetter = (str: string | undefined) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{ ...modalVisibleStyle, height: "100%", width: "90%" }}
        component="form"
        noValidate
        onSubmit={() => {}}
      >
        <Typography textAlign="center" variant="h4" sx={{ mb: 1 }}>
          {t("candidateProfile")}
        </Typography>

        {candidate && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  padding: 2,
                  borderRadius: "10px",
                }}
              >
                <Box>
                  <Avatar
                    sx={{ bgcolor: "#1976d2" }}
                  >{`${candidate.firstName[0]}`}</Avatar>
                </Box>
                <Box>
                  <Typography variant="h5" sx={{ mb: 1 }}>
                    {`${capitalizeFirstLetter(
                      candidate.firstName
                    )} ${capitalizeFirstLetter(candidate.lastName)}`}
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                    <Email />
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      {`${candidate.email}`}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                    <LinkedIn />
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      {`${candidate.linkedinUrl || "-"}`}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                    <Phone />
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      {`${"070050360"}`}
                    </Typography>
                  </Box>
                </Box>
              </Card>
              {/* jobs */}
              <Divider></Divider>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  padding: 2,
                }}
              >
                <Typography variant="h5">{t("Jobs")}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    border: "1px solid steelblue",
                    marginTop: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: 2,
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "blue" }}>
                      {`${capitalizeFirstLetter(candidateJob?.title)}`}
                    </Typography>
                    <Typography variant="subtitle1" sx={{}}>
                      {`Company: ${candidateJob?.company?.name}`}
                    </Typography>
                    <Typography variant="subtitle1" sx={{}}>
                      {`Stage: ${candidate.stage?.title}`}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
            {/* right side of the profile */}
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              sx={{ display: "flex", flexDirection: "row", gap: 2, flex: 2 }}
            >
              {/* to do add more options and windows */}
            </Box>
          </Box>
        )}

        <Button
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            marginTop: "16px",
            marginLeft: "16px",
            border: "none",
            "&:hover": {
              border: "none",
            },
          }}
          onClick={() =>
            close ? close() : console.log("Close function not defined")
          }
          variant="outlined"
          startIcon={<CancelIcon sx={{ height: "40px", width: "40px" }} />} // Increase the icon size (e.g., fontSize: 28)
        ></Button>
      </Box>
    </Modal>
  );
};

export default CandidateProfile;
