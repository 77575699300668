import { type MRT_ColumnDef, type MRT_Row } from "material-react-table";

import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import { ExportToCsv } from "export-to-csv";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout";
import { AppDispatch, RootState } from "../../utils/store";
import { useEffect, useMemo, useState } from "react";
import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import {
  Box,
  Grid,
  Menu,
  MenuItem,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import TextField from "../../components/UI/TextField";
import Table from "../../components/elements/Table";
import Button from "../../components/UI/Button";
import Candidate from "../../types/Candidate";
import { deleteJobs, initializeCompanies } from "../../reducers/companies";
import { UserRole } from "../../types/User";
import { useNavigate } from "react-router-dom";
import { notify } from "../../reducers/notification";
import { NotificationLevel } from "../../types/Notification";
import jobsService from "../../services/jobs";
import { useTranslation } from "react-i18next";
import { Job } from "../../types/Company";
import CompanyComponent from "../../components/Client/CompanyComponentClient";
import Modal from "../../components/UI/Modal";
import ActionsBar from "../../components/elements/ActionsBar";
import styles from "./Modal.module.scss";

export default function Jobs() {
  const [t] = useTranslation(["common"]);
  const [view, setView] = useState("list");
  const [addJobTitle, setAddJobTitle] = useState<string>("");
  const [addJobDescription, setAddJobDescription] = useState<string>("");
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);
  const [selectedJobIdToAssign, setSelectedJobIdToAssign] = useState<
    number | null
  >(null);
  const [editJobTitle, setEditJobTitle] = useState<string>("");
  const [editJobDescription, setEditJobDescription] = useState<string>("");
  const [assignToClientEmail, setAssignToClientEmail] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState("");
  const [rowSelection, setRowSelection] = useState({});

  const [jobDescription, setJobDescription] = useState<string | null>(null);
  const { user, companies } = useSelector((state: RootState) => ({
    user: state.user,
    companies: state.companies,
  }));
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/exhaustive-deps

  console.log(companies);
  const candidates =
    companies[0]?.jobs
      ?.map(({ title, stages }) =>
        stages.map((stage) => ({
          ...stage,
          candidates: stage.candidates.map((candidate) => ({
            ...candidate,
            stage: {
              id: stage.id,
              title: stage.title,
              job: {
                title,
              },
            },
          })),
        }))
      )
      ?.flat()
      ?.map(({ candidates }) => candidates)
      ?.flat() || [];

  const columns: MRT_ColumnDef<Candidate & { stageText: string }>[] =
    useMemo(() => {
      return [
        {
          accessorKey: "id",
          header: "ID",
        },
        {
          accessorKey: "id",
          header: "Actions",
          muiTableHeadCellProps: {
            align: "center",
          },
          Cell: ({ cell }: any) => {
            return (
              <Box>
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedJobId(cell.getValue());
                  }}
                  variant="contained"
                  sx={{ mr: 2 }}
                >
                  {t("edit")}
                </Button>
              </Box>
            );
          },
        },
        {
          accessorKey: "id",
          header: "Actions",
          muiTableHeadCellProps: {
            align: "center",
          },
          Cell: ({ cell }: any) => {
            return (
              <Box>
                {((user as any)?.isClientOwner || user?.role === "admin") && (
                  <Button
                    color="primary"
                    onClick={() => {
                      setSelectedJobIdToAssign(cell.getValue());
                    }}
                    variant="contained"
                  >
                    {t("assignToClient")}
                  </Button>
                )}
              </Box>
            );
          },
        },
        {
          accessorKey: "title",
          header: "Title",
          muiTableHeadCellProps: {
            align: "left",
          },
          muiTableBodyCellProps: {
            align: "left",
          },
        },
        {
          accessorKey: "description",
          header: "Description",
          Cell: ({ cell }: any) => {
            if (cell.getValue()?.length > 14) {
              return (
                <>
                  <p>
                    {cell.getValue().slice(0, 14)} ...{" "}
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => setJobDescription(cell.getValue())}
                    >
                      More
                    </span>
                  </p>
                </>
              );
            } else {
              return <p>{cell.getValue()}</p>;
            }
          },
          muiTableHeadCellProps: {
            align: "left",
          },
          muiTableBodyCellProps: {
            align: "left",
          },
        },
        {
          accessorKey: "candidates",
          header: "Assigned Candidates",
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: {
            align: "center",
          },
        },
        {
          accessorKey: "id",
          header: "Actions",
          muiTableHeadCellProps: {
            align: "right",
          },
          muiTableBodyCellProps: {
            align: "right",
          },
          Cell: ({ cell }: any) => {
            return (
              <Button
                color="primary"
                onClick={() => navigate(`/jobs/${cell.getValue()}`)}
                variant="contained"
              >
                {t("open")}
              </Button>
            );
          },
        },
      ] as any;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies]);

  const csvOptions = {
    filename: "jobs",
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(
    null
  );
  const [openCreateModal, setCreateOpenModal] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const handleExportRows = (
    rows: MRT_Row<Candidate & { stageText: string }>[]
  ) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(candidates);
  };
  const handleChangeView = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView(nextView);
  };
  const handleOpenExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElExport(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorElExport(null);
  };

  const handleOpenCreateModal = () => setCreateOpenModal(true);

  const handleCloseCreateModal = () => setCreateOpenModal(false);

  const handleAddJobSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const title = addJobTitle;
    const description = addJobDescription;
    if (title) {
      const ceateJobtDto = { title, description };
      try {
        jobsService.create(ceateJobtDto as any).then(() => {
          dispatch(
            notify({
              message: "A new job was added successfully",
              level: NotificationLevel.SUCCESS,
            })
          );
          dispatch(initializeCompanies(true));
        });
      } catch (error) {
        dispatch(
          notify({
            message: "Failed to add the new job",
            level: NotificationLevel.ERROR,
          })
        );
      }
      handleCloseCreateModal();
    }
  };

  const handleEditJobSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const title = editJobTitle;
    const description = editJobDescription;
    if (title) {
      const updatedJobDto = { title, description } as any;
      try {
        jobsService
          .update(selectedJobId as number, updatedJobDto as any)
          .then(() => {
            dispatch(
              notify({
                message: "A new job was added successfully",
                level: NotificationLevel.SUCCESS,
              })
            );
            dispatch(initializeCompanies(true));
          });
      } catch (error) {
        dispatch(
          notify({
            message: "Failed to add the new job",
            level: NotificationLevel.ERROR,
          })
        );
      }
      setSelectedJobId(null);
      setEditJobDescription("");
      setEditJobTitle("");
    }
  };

  const handleAssignJobSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const emails = assignToClientEmail;
    if (emails) {
      const updatedJobDto = { emails, jobId: selectedJobIdToAssign } as any;
      try {
        jobsService.assignJobToClient(updatedJobDto).then(() => {
          dispatch(
            notify({
              message: "The job was assigned successfully",
              level: NotificationLevel.SUCCESS,
            })
          );
          dispatch(initializeCompanies(true));
        });
      } catch (error) {
        dispatch(
          notify({
            message: "Failed to assign the job",
            level: NotificationLevel.ERROR,
          })
        );
      }
      setSelectedJobIdToAssign(null);
    }
  };

  const handleDeleteJobs = () => {
    const jobs = Object.keys(rowSelection).map((key) => filteredData[key]);
    dispatch(
      deleteJobs(
        jobs.map((job) => job.id),
        true
      )
    );
  };

  const data =
    (companies[0]?.jobs?.map((job) => ({
      ...job,
      candidates: job.stages.map((stage) => stage.candidates).flat()?.length,
    })) as any) || ([] as any);

  const filteredData = useMemo(() => {
    return data.filter((job) => {
      return job.title.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
  }, [data, searchTerm]);

  useEffect(() => {
    if (user && candidates?.length <= 0) {
      dispatch(initializeCompanies(true));
    }
  }, []);

  return (
    <>
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add Job"
        deleteHandler={handleDeleteJobs}
        deleteBtnLabel="Delete Jobs"
        addHandler={handleOpenCreateModal}
      />
      <ToggleButtonGroup
        orientation="horizontal"
        value={view}
        exclusive
        onChange={handleChangeView}
      >
        <ToggleButton value="list" aria-label="list">
          <ViewListIcon />
        </ToggleButton>
        <ToggleButton value="module" aria-label="module">
          <ViewModuleIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      {companies[0] && view === "module" && (
        <CompanyComponent company={companies[0]} />
      )}
      {view === "list" && (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Table
              columns={columns}
              data={filteredData}
              rowSelection={rowSelection}
              onRowSelectionChange={setRowSelection}
            />
          </Grid>
        </Grid>
      )}
      <Modal
        isOpen={openCreateModal}
        onCancel={handleCloseCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          noValidate
          className={styles.modalContent}
          onSubmit={handleAddJobSubmit}
        >
          <Typography variant="h4" component="h3">
            Add New Job
          </Typography>
          <TextField
            required
            fullWidth
            onChange={(e) => setAddJobTitle(e.target.value)}
            value={addJobTitle}
            label={t("jobTitle")}
            name="jobTitle"
          />
          <TextField
            required
            fullWidth
            onChange={(e) => setAddJobDescription(e.target.value)}
            value={addJobDescription}
            label={t("Job Description")}
            name="jobDescription"
          />
          <Box className={styles.buttons}>
            <Button type="submit" color="primary" className={styles.add}>
              {t("addNewJob")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => handleCloseCreateModal()}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      {jobDescription && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box component="form" noValidate onSubmit={() => {}}>
            <Typography sx={{ mb: 1 }}>{jobDescription}</Typography>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                setJobDescription(null);
              }}
            >
              close
            </Button>
          </Box>
        </Modal>
      )}
      {selectedJobId && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            className={styles.modalContent}
            noValidate
            onSubmit={handleEditJobSubmit}
          >
            <Typography variant="h4" component="h3">
              Edit Job
            </Typography>
            <TextField
              required
              label={t("jobTitle")}
              onChange={(e) => setEditJobTitle(e.target.value)}
              value={editJobTitle}
              name="jobTitle"
            />
            <TextField
              required
              fullWidth
              onChange={(e) => setEditJobDescription(e.target.value)}
              value={editJobDescription}
              label={t("Job Description")}
              name="jobDescription"
            />
            <Box className={styles.buttons}>
              <Button type="submit" color="primary" className={styles.add}>
                {t("edit")}
              </Button>
              <Button
                variant="contained"
                color="danger"
                onClick={() => setSelectedJobId(null)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      )}{" "}
      {selectedJobIdToAssign && (
        <Modal
          isOpen={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            noValidate
            className={styles.modalContent}
            onSubmit={handleAssignJobSubmit}
          >
            <Typography variant="h4" component="h3">
              Assign To Client
            </Typography>
            <TextField
              required
              onChange={(e) => setAssignToClientEmail(e.target.value)}
              value={assignToClientEmail}
              label={t("emails")}
              name="emails"
            />
            <Box className={styles.buttons}>
              <Button type="submit" color="primary" className={styles.add}>
                {t("edit")}
              </Button>
              <Button
                variant="contained"
                color="danger"
                onClick={() => setSelectedJobIdToAssign(null)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
