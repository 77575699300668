import Home from "./Home";
import Candidates from "./Candidates";
import Clients from "./Clients/index";
import Login from "./Login";
import Jobs from "./Jobs";
import Stages from "./Stages";
import Client from "./Client/index";
import CandidatesPage from "./CandidatesPage";
import Recruiters from "./Recruiters";
import Users from "./Users";
import Roles from "./Roles";


const commonRoutes = [
  {
    component: Home,
    url: "/",
  },
  {
    component: Candidates,
    url: "/candidates",
  },
  {
    component: CandidatesPage,
    url: "/candidates/pages/:id",
  },
  {
    component: Clients,
    url: "/clients",
  },
  {
    component: Client,
    url: "/clients/:id",
  },
];

const adminRoutes = [
  {
    component: Login,
    url: "/login",
  },
  {
    component: Roles,
    url: "/roles",
  },

  {
    component: Recruiters,
    url: "/recruiters",
  },
  {
    component: Users,
    url: "/users",
  },
];

const clientRoutes = [
  {
    component: Jobs,
    url: "/jobs",
  },
  {
    component: Stages,
    url: "/jobs/:id",
  },
];

const routes = [
  ...commonRoutes,
  ...adminRoutes,
  ...clientRoutes,
];

export default routes;
