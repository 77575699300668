import { User } from "../types/User";
import axios from "../utils/axios";
import config from "../utils/config";

export interface UserWithToken {
  accessToken: string;
  user: User;
}

export interface Credentials {
  email: string;
  password: string;
}

const baseUrl = `${config.backendUrl}/auth`;
const login = async (credentials: Credentials): Promise<UserWithToken> => {
  const { data } = await axios.post<UserWithToken>(
    `${baseUrl}/login`,
    credentials
  );
  return data;
};

const authService = { login };

export default authService;
