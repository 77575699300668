interface IconProps {
  className?: string;
  title?: string;
  color?: string;
  textColor?: string;
  iconColor?: string;
  style?: React.CSSProperties;
}

export const AddIcon = ({ className, style }: IconProps) => (
  <svg
    width="21"
    height="21"
    className={className}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={style}
  >
    <rect x="0.25" y="0.5" width="20" height="20" fill="url(#pattern0)" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_784_5075" transform="scale(0.0208333)" />
      </pattern>
      <image
        id="image0_784_5075"
        width="48"
        height="48"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1UlEQVR4nO2Zy07CQBSGCV4WLr2sQN9Cog9iMPogGB4A3AtB4yMYceFl41No8AFUVgKuqZvPnDgmhEiY6bTTQ+yfNGlSmPm/pj1z5m+hkCtXLm8BS8A+UAeugRfgE/gyh5z3zDX5zR5Q1GB8GzgF+rjrHWgC5SyMbwEXQIS/IqADbIYyfwSMSF5D4DBN4yvAJenrXOZK2vwa8EA43cucSd75kOZ/9QisJgEQ4rGZpY6v+WOyVzWu+Q1gkLV7fiqee4k1dV6L2q7mywktUkkpAnZcAKQ98NIfY/qqaWu+aPoUbQB9aRptAKSrRCGAqGIDIO2uVoATG4CuYoArGwDZeGgFeLYBcGqV5w44fz4XDWwGjBQDjP8FwGjRH6Ge46BWYCFf4u6il9G6YoCaDYCETloBdm2buTeFAK/WaZ5JzLQBNKzMK93QjIGSNYCBkLhPi86czBuAdSWb+mHs3FSyyqzdAwexzE9ASFaZlVpe5ic+XtxkYP4OWPYGmAh3JXANpdvEwt2pkDdEZWoldudngFRTqk4f3i+sY27aNguMr8ZS56VsBzE/BVIybUec3kn+03BeYVMCkQawIrmN9Oyy8TA7u8gccv5krtWkq1TxmTVXrsLi6xvPU5lyxNccQQAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);

export const ArrowDownIcon = ({ className }: IconProps) => (
  <svg width="36" height="35" className={className} viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.85 11L18.5 18.6333L26.15 11L28.5 13.35L18.5 23.35L8.5 13.35L10.85 11Z" fill="white" />
  </svg>
);

export const CorrectIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"></path>
  </svg>
);

export const CompletedIcon = ({ className }: IconProps) => (
  <svg width="10" height="10" className={className} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.295 2.79L4 6.085L2.205 4.295L1.5 5L4 7.5L8 3.5L7.295 2.79ZM5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5 9C2.79 9 1 7.21 1 5C1 2.79 2.79 1 5 1C7.21 1 9 2.79 9 5C9 7.21 7.21 9 5 9Z"
      fill="white"
    />
  </svg>
);

export const DeleteIcon = ({ className }: IconProps) => (
  <svg width="18" height="24" className={className} viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 20.75C1.5 22.125 2.625 23.25 4 23.25H14C15.375 23.25 16.5 22.125 16.5 20.75V5.75H1.5V20.75ZM17.75 2H13.375L12.125 0.75H5.875L4.625 2H0.25V4.5H17.75V2Z"
      fill="white"
    />
  </svg>
);

export const DepthEffect = ({ className }: IconProps) => (
  <svg
    width="20"
    height="20"
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="20" height="20" fill="url(#pattern0)" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_417_4105" transform="scale(0.0208333)" />
      </pattern>
      <image
        id="image0_417_4105"
        width="48"
        height="48"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACp0lEQVR4nO2ZO2hUQRSGxxh1Q0JEBfGBoIWKGI2xWGMhWAhWNoIWFhIImFIrBW0sLRNttFEIJIWFjViJhSI2VhERDSjRCOIDFUVdEzefjJyrm5v7mrmPnYv7w212Luf839zZMy+lWgoXsApYocomYAlwAngHfATOAB2qDAIOAI9ZqGmBWqxcFLANuEW8ngBHlGPjfAT4hZkeAPuaaXwpcBL4TDrdBnqKNn8IeE52mgWuAGvzNr4buEt++gZcAJZnbXyd9JDpOLfVBym9y9Ia75BAX2iOXkrpbbMxfxh4hRu6B3SbAqyR8lhrovEacDHVnxvYION/pkDjP4HLOre18QCQjcBVKXl5aRa4BmxKY/Q00BnRvgUYA+oZGq8D48DWiLyd2lsSAK23wCmgEvFeD3ADmEthfE5i7IjIUxEv2hNJARpXkEN6eRzx/tkUAOdiluRD4uGvTAE8vQAGgHbfu+3ApO/dH8AwUJXPrp89IRVtMiTmgORcIFsAT8+AY97EAgz62nVv7YyI3evvUWBQ2tokts4RqrQAnvSG5Sgw5ev5UPM+iMYvMSWxgjZBuQEEaTg2+L8cenKyUp4AVQOAfhcBugwAusoO0O0iQLXsQ2jEAOCSiwA1XSITxN8lq03nAJBJqjfG/Os0CVTOAI2bkH6pNvrZK8PGuueLBMhVqgXwH32B676nMICJPBJlEROYSAKg1+XHgfcOAXySg+R5m584kJWWx+ZZAtSBUWB1YuMBifuA+00AeKjnEmvjvuSL9K2KyVFjCoA31uehCUD0Bv18kuNGC4AZGbJmZ6CWIJvj7sMMAe4A23M3bnJTkxBgWle8wo2H3JV9NQD4LkOxolwRsF5K3p+jxoB2Tzf1QbFyVcB+4FHA70+Bg6oMImDGNJpFW1Ll0W/9z+pEZJbTjQAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);

export const DraftIcon = ({ className }: IconProps) => (
  <svg width="8" height="8" className={className} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M0 6.33357V8H1.66644L6.58131 3.08513L4.91487 1.41869L0 6.33357ZM7.87002 1.79642C8.04333 1.62311 8.04333 1.34315 7.87002 1.16984L6.83016 0.129982C6.65685 -0.0433273 6.37689 -0.0433273 6.20358 0.129982L5.39036 0.943202L7.0568 2.60964L7.87002 1.79642Z"
      fill="white"
      fill-opacity="0.5"
    />
  </svg>
);

export const MenuIcon = ({ className }: IconProps) => (
  <svg className={className} width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.25 12.75H19V10.6667H0.25V12.75ZM0.25 7.54167H19V5.45833H0.25V7.54167ZM0.25 0.25V2.33333H19V0.25H0.25Z"
      fill="white"
    />
  </svg>
);

export const EmailIcon = ({ className }: IconProps) => (
  <svg width="15" height="15" className={className} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.945 0.0926971L0.365916 7.92668C-0.164357 8.23137 -0.0969742 8.96965 0.43037 9.19231L3.54463 10.4989L11.9616 3.08098C12.1228 2.93742 12.3513 3.15715 12.2136 3.32414L5.15596 11.9228V14.2812C5.15596 14.9726 5.99092 15.245 6.40107 14.7441L8.26143 12.4794L11.9118 14.0087C12.3278 14.1845 12.8024 13.9238 12.8786 13.4755L14.988 0.81926C15.0876 0.227463 14.4519 -0.200272 13.945 0.0926971Z"
      fill="#BDBDBD"
    />
  </svg>
);

export const MailOutlineIcon = ({ className }: IconProps) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_417_4099)">
      <path
        d="M16.666 3.33301H3.33268C2.41602 3.33301 1.67435 4.08301 1.67435 4.99967L1.66602 14.9997C1.66602 15.9163 2.41602 16.6663 3.33268 16.6663H16.666C17.5827 16.6663 18.3327 15.9163 18.3327 14.9997V4.99967C18.3327 4.08301 17.5827 3.33301 16.666 3.33301ZM16.666 14.9997H3.33268V6.66634L9.99935 10.833L16.666 6.66634V14.9997ZM9.99935 9.16634L3.33268 4.99967H16.666L9.99935 9.16634Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_4099">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const MailFilledIcon = ({ className }: IconProps) => (
  <svg className={className} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.666 0.333008H2.33268C1.41602 0.333008 0.674349 1.08301 0.674349 1.99967L0.666016 11.9997C0.666016 12.9163 1.41602 13.6663 2.33268 13.6663H15.666C16.5827 13.6663 17.3327 12.9163 17.3327 11.9997V1.99967C17.3327 1.08301 16.5827 0.333008 15.666 0.333008ZM15.666 3.66634L8.99935 7.83301L2.33268 3.66634V1.99967L8.99935 6.16634L15.666 1.99967V3.66634Z"
      fill="white"
    />
  </svg>
);

export const MediationIcon = ({ className }: IconProps) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_417_4102)">
      <path
        d="M18.3327 9.99967L14.9993 13.333L13.8243 12.158L15.141 10.833H10.7827C10.4993 13.4163 8.89935 15.5997 6.66602 16.708C6.63268 18.0747 5.53268 19.1663 4.16602 19.1663C2.78268 19.1663 1.66602 18.0497 1.66602 16.6663C1.66602 15.283 2.78268 14.1663 4.16602 14.1663C4.95768 14.1663 5.64935 14.5413 6.10768 15.1163C7.69102 14.258 8.82435 12.6913 9.09102 10.833H6.50768C6.16602 11.7997 5.24935 12.4997 4.16602 12.4997C2.78268 12.4997 1.66602 11.383 1.66602 9.99967C1.66602 8.61634 2.78268 7.49967 4.16602 7.49967C5.24935 7.49967 6.16602 8.19967 6.51602 9.16634H9.09935C8.83268 7.30801 7.69102 5.74967 6.10768 4.88301C5.64935 5.45801 4.95768 5.83301 4.16602 5.83301C2.78268 5.83301 1.66602 4.71634 1.66602 3.33301C1.66602 1.94967 2.78268 0.833008 4.16602 0.833008C5.53268 0.833008 6.63268 1.92467 6.65768 3.29134C8.89102 4.39967 10.491 6.58301 10.7743 9.16634H15.1327L13.816 7.84134L14.9993 6.66634L18.3327 9.99967Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_4102">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const PausedIcon = ({ className }: IconProps) => (
  <svg className={className} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 7H4.5V3H3.5V7ZM5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5 9C2.795 9 1 7.205 1 5C1 2.795 2.795 1 5 1C7.205 1 9 2.795 9 5C9 7.205 7.205 9 5 9ZM5.5 7H6.5V3H5.5V7Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
);

export const PolygonIcon = ({ className }: IconProps) => (
  <svg className={className} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 7H4.5V3H3.5V7ZM5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5 9C2.795 9 1 7.205 1 5C1 2.795 2.795 1 5 1C7.205 1 9 2.795 9 5C9 7.205 7.205 9 5 9ZM5.5 7H6.5V3H5.5V7Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
);

export const ProfileIcon = ({ className }: IconProps) => (
  <svg className={className} width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Profile">
      <path
        id="Stroke 1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98712 11.7891C3.7641 11.7891 1.01172 12.2764 1.01172 14.228C1.01172 16.1795 3.74664 16.6843 6.98712 16.6843C10.2101 16.6843 12.9617 16.1962 12.9617 14.2454C12.9617 12.2946 10.2276 11.7891 6.98712 11.7891Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Stroke 3"
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98643 9.00523C9.10151 9.00523 10.8158 7.29015 10.8158 5.17507C10.8158 3.05999 9.10151 1.3457 6.98643 1.3457C4.87135 1.3457 3.15627 3.05999 3.15627 5.17507C3.14913 7.28301 4.8523 8.99808 6.95945 9.00523H6.98643Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const SearchIcon = ({ className, iconColor }: IconProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={iconColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_4096)">
      <path
        d="M12.9167 11.6667H12.2583L12.025 11.4417C12.8417 10.4917 13.3333 9.25833 13.3333 7.91667C13.3333 4.925 10.9083 2.5 7.91667 2.5C4.925 2.5 2.5 4.925 2.5 7.91667C2.5 10.9083 4.925 13.3333 7.91667 13.3333C9.25833 13.3333 10.4917 12.8417 11.4417 12.025L11.6667 12.2583V12.9167L15.8333 17.075L17.075 15.8333L12.9167 11.6667ZM7.91667 11.6667C5.84167 11.6667 4.16667 9.99167 4.16667 7.91667C4.16667 5.84167 5.84167 4.16667 7.91667 4.16667C9.99167 4.16667 11.6667 5.84167 11.6667 7.91667C11.6667 9.99167 9.99167 11.6667 7.91667 11.6667Z"
        fill="#FFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_4096">
        <rect width="20" height="20" fill={iconColor} />
      </clipPath>
    </defs>
  </svg>
);

export const SelectCursorIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="20" height="20" fill="url(#pattern0)" fillOpacity="1" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_1945_2275" transform="scale(0.0208333)" />
      </pattern>
      <image
        id="image0_1945_2275"
        width="48"
        height="48"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABcklEQVR4nOXZv0ocURTA4WOIhSlsUwQEhRB7wSqvkEDAlLaCtgHzCmktLUUsrJMuW4Y0KUR7YyGWWoiQJSx+YTASFtZ1/9yZuZd8T3DOcIcfcyfif4HZKBm+Yj5K5c4xXkTBC1R+4lWURr9LvI6CF6j8wlqUwmA9bEbBC9zbwUzkzOP2sm6F0eTbCqPLsxXGk18rjC+vVphM1Yr3UfAC963YKnmBPFohjfZaIZ1OK62Q1knjrZDeGZZLXqDSXCvUp9tIK9SrV3srNGOntlZozl4trdCsTvJWaN5J0lZox1myVmjPZZJWaFd36lZoX2+qVrQ8/DV+YB+LOS9whW/YxUe8wRKeTPzka1jgN07xGZ+wUb2ktX8jTDFwdU+0jbd4iae1DvqQCYf/ks1NnfHc/j0e05/dVMa8C1qP3BjNBVYjRx53hIXIleEO8SxyZvjLmvffmcqA4W/wLkqh3zlWoiT++Y7nURp3DjAXJcKHtmcY5A+HqYHFRs7fUgAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);
export const UpdateIcon = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_769_3227)">
      <path
        d="M21.8757 11.0417H14.8132L17.6674 8.10417C14.8236 5.29167 10.2194 5.1875 7.37569 8C4.53194 10.8229 4.53194 15.375 7.37569 18.1979C10.2194 21.0208 14.8236 21.0208 17.6674 18.1979C19.084 16.8021 19.7924 15.1667 19.7924 13.1042H21.8757C21.8757 15.1667 20.959 17.8437 19.1257 19.6562C15.4694 23.2812 9.53194 23.2812 5.87569 19.6562C2.22985 16.0417 2.1986 10.1667 5.85485 6.55208C9.5111 2.9375 15.3757 2.9375 19.0319 6.55208L21.8757 3.625V11.0417ZM13.0215 8.83333V13.2604L16.6674 15.4271L15.9174 16.6875L11.459 14.0417V8.83333H13.0215Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_769_3227">
        <rect width="25" height="25" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const DateIcon = ({ className }: IconProps) => (
  <svg className={className} width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_769_3249)">
      <path
        d="M9.375 11.958H7.29167V14.0413H9.375V11.958ZM13.5417 11.958H11.4583V14.0413H13.5417V11.958ZM17.7083 11.958H15.625V14.0413H17.7083V11.958ZM19.7917 4.66634H18.75V2.58301H16.6667V4.66634H8.33333V2.58301H6.25V4.66634H5.20833C4.05208 4.66634 3.13542 5.60384 3.13542 6.74967L3.125 21.333C3.125 22.4788 4.05208 23.4163 5.20833 23.4163H19.7917C20.9375 23.4163 21.875 22.4788 21.875 21.333V6.74967C21.875 5.60384 20.9375 4.66634 19.7917 4.66634ZM19.7917 21.333H5.20833V9.87467H19.7917V21.333Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_769_3249">
        <rect width="25" height="25" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const SettingsIcon = ({ className }: IconProps) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="#B9B9B9" />
    <g id="campaign" clipPath="url(#clip0_15_2)">
      <rect width="1512" height="982" transform="translate(-30 -932)" fill="#111525" />
      <g id="side bar">
        <g id="Rectangle 92" filter="url(#filter0_bd_15_2)">
          <path
            d="M-30 -932H50V-735.84C50 -734.079 49.0732 -732.448 47.5603 -731.546L26.4397 -718.954C24.9268 -718.052 24 -716.421 24 -714.66V-699V-688V-687.84C24 -686.079 24.9268 -684.448 26.4397 -683.546L47.5603 -670.954C49.0732 -670.052 50 -668.421 50 -666.66V50H-30V-932Z"
            fill="#161B2E"
          />
          <path
            d="M-29.5 -931.5H49.5V-735.84C49.5 -734.255 48.6659 -732.787 47.3043 -731.975L26.1836 -719.384C24.5194 -718.392 23.5 -716.597 23.5 -714.66V-699V-688V-687.84C23.5 -685.903 24.5194 -684.108 26.1836 -683.116L47.3043 -670.525C48.6659 -669.713 49.5 -668.245 49.5 -666.66V49.5H-29.5V-931.5Z"
            stroke="url(#paint0_linear_15_2)"
          />
        </g>
        <g id="Settings" clipPath="url(#clip1_15_2)">
          <g id="Group">
            <path
              id="Vector"
              d="M15.9504 10.7833C15.9838 10.5333 16.0004 10.275 16.0004 10C16.0004 9.73333 15.9838 9.46667 15.9421 9.21667L17.6338 7.9C17.7838 7.78333 17.8254 7.55833 17.7338 7.39167L16.1338 4.625C16.0338 4.44167 15.8254 4.38333 15.6421 4.44167L13.6504 5.24167C13.2337 4.925 12.7921 4.65833 12.3004 4.45833L12.0004 2.34167C11.9671 2.14167 11.8004 2 11.6004 2H8.40042C8.20042 2 8.04208 2.14167 8.00875 2.34167L7.70875 4.45833C7.21708 4.65833 6.76708 4.93333 6.35875 5.24167L4.36708 4.44167C4.18375 4.375 3.97542 4.44167 3.87542 4.625L2.28375 7.39167C2.18375 7.56667 2.21708 7.78333 2.38375 7.9L4.07542 9.21667C4.03375 9.46667 4.00042 9.74167 4.00042 10C4.00042 10.2583 4.01708 10.5333 4.05875 10.7833L2.36708 12.1C2.21708 12.2167 2.17542 12.4417 2.26708 12.6083L3.86708 15.375C3.96708 15.5583 4.17542 15.6167 4.35875 15.5583L6.35042 14.7583C6.76708 15.075 7.20875 15.3417 7.70042 15.5417L8.00042 17.6583C8.04208 17.8583 8.20042 18 8.40042 18H11.6004C11.8004 18 11.9671 17.8583 11.9921 17.6583L12.2921 15.5417C12.7837 15.3417 13.2338 15.075 13.6421 14.7583L15.6338 15.5583C15.8171 15.625 16.0254 15.5583 16.1254 15.375L17.7254 12.6083C17.8254 12.425 17.7838 12.2167 17.6254 12.1L15.9504 10.7833ZM10.0004 13C8.35042 13 7.00042 11.65 7.00042 10C7.00042 8.35 8.35042 7 10.0004 7C11.6504 7 13.0004 8.35 13.0004 10C13.0004 11.65 11.6504 13 10.0004 13Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_bd_15_2"
        x="-50"
        y="-952"
        width="120"
        height="1022"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_15_2" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="effect1_backgroundBlur_15_2" result="effect2_dropShadow_15_2" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_15_2" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_15_2" x1="50" y1="-932" x2="50" y2="50" gradientUnits="userSpaceOnUse">
        <stop offset="0.130208" stopColor="#1090FA" stop-opacity="0" />
        <stop offset="0.234375" stopColor="#0E92FD" />
        <stop offset="0.348958" stopColor="#1683F2" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_15_2">
        <rect width="1512" height="982" fill="white" transform="translate(-30 -932)" />
      </clipPath>
      <clipPath id="clip1_15_2">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CloseIcon = ({ className }: IconProps) => (
  <svg className={className} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7923 1.67712L13.3236 0.208374L7.50065 6.03129L1.67773 0.208374L0.208984 1.67712L6.0319 7.50004L0.208984 13.323L1.67773 14.7917L7.50065 8.96879L13.3236 14.7917L14.7923 13.323L8.9694 7.50004L14.7923 1.67712Z"
      fill="white"
    />
  </svg>
);

export const SunIcon = ({ className }: IconProps) => (
  <svg className={className} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.225 3.025L3.1 1.90625L2.21875 2.7875L3.3375 3.90625L4.225 3.025ZM2.5 6.5625H0.625V7.8125H2.5V6.5625ZM8.125 0.34375H6.875V2.1875H8.125V0.34375ZM12.7812 2.7875L11.9 1.90625L10.7812 3.025L11.6625 3.90625L12.7812 2.7875ZM10.775 11.35L11.8938 12.475L12.775 11.5938L11.65 10.475L10.775 11.35ZM12.5 6.5625V7.8125H14.375V6.5625H12.5ZM7.5 3.4375C5.43125 3.4375 3.75 5.11875 3.75 7.1875C3.75 9.25625 5.43125 10.9375 7.5 10.9375C9.56875 10.9375 11.25 9.25625 11.25 7.1875C11.25 5.11875 9.56875 3.4375 7.5 3.4375ZM6.875 14.0313H8.125V12.1875H6.875V14.0313ZM2.21875 11.5875L3.1 12.4688L4.21875 11.3438L3.3375 10.4625L2.21875 11.5875Z"
      fill="white"
    />
  </svg>
);

export const DoneIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="10" height="10" fill="url(#pattern0)" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_594_1384" transform="scale(0.0208333)" />
      </pattern>
      <image
        id="image0_594_1384"
        width="48"
        height="48"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtUlEQVR4nO3WMQ6DMAxGYR+p3IaRMvUYjD1021chwVIEIiSSbfX/JAamvBgUMBMRERHZAG6WFXAH3sDTEsev8mwCGH/iWe47Szj52Qd4WHQo3gmavBNN/u8mT4P/koOP1Nimcn/hqXZKnpOfahf0jO9qH7nba9NiemGOSi6EhIm/EhQuviQsbPyZwPDxJ04Wv9Om1M60Y0++YBPx4w82kSd+BQzAa7kGywjo58u7Q0RERMTKfAHmM2Fubl54agAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);

export const CalendarIcon = ({ className }: IconProps) => (
  <svg className={className} width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.625 6.375H3.375V7.625H4.625V6.375ZM7.125 6.375H5.875V7.625H7.125V6.375ZM9.625 6.375H8.375V7.625H9.625V6.375ZM10.875 2H10.25V0.75H9V2H4V0.75H2.75V2H2.125C1.43125 2 0.88125 2.5625 0.88125 3.25L0.875 12C0.875 12.6875 1.43125 13.25 2.125 13.25H10.875C11.5625 13.25 12.125 12.6875 12.125 12V3.25C12.125 2.5625 11.5625 2 10.875 2ZM10.875 12H2.125V5.125H10.875V12Z"
      fill="#848484"
    />
  </svg>
);

export const ChevronShape = ({ className, title, color, textColor }: IconProps) => (
  <svg
    className={className}
    width="211"
    height="73"
    viewBox="0 0 211 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_bd_510_3613)">
      <path d="M4 4H172.165L199 32.5L172.165 61H4L27.5923 32.5L4 4Z" fill={color} />
    </g>
    <defs>
      <filter
        id="filter0_bd_510_3613"
        x="-16"
        y="-16"
        width="235"
        height="97"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_510_3613" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="effect1_backgroundBlur_510_3613" result="effect2_dropShadow_510_3613" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_510_3613" result="shape" />
      </filter>
    </defs>
    <text x="50%" y="45%" dominant-baseline="middle" text-anchor="middle" fill={textColor || 'black'}>
      {title}
    </text>{' '}
  </svg>
);

export const DotsIcon = () => (
  <svg width="6" height="20" viewBox="0 0 6 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="3" r="2.5" fill="white" />
    <circle cx="3" cy="10" r="2.5" fill="white" />
    <circle cx="3" cy="17" r="2.5" fill="white" />
  </svg>
);

export const LinearMessagesIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="32" height="32" rx="16" fill="url(#paint0_linear_470_1351)" />
    <rect x="6" y="6" width="20" height="20" fill="url(#pattern0)" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_470_1351" transform="scale(0.0208333)" />
      </pattern>
      <linearGradient id="paint0_linear_470_1351" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00D1FF" />
        <stop offset="1" stopColor="#BD00FF" />
      </linearGradient>
      <image
        id="image0_470_1351"
        width="48"
        height="48"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAqJJREFUaEPtWD+L1EAUf78YZiaBEy0VT0tLDxFFv4CNglZWdoKFngiepQdqo6iNXiP4CbTwQAU/gQcniH4FT+wsPNndTCB5ErlIzOaSyc5k19Nst2Teb35/3pudDWiHf7DD+VMvYNYJ9gn0CVg68G+2UBRFZ5h5GcARIhKWJtmWx8z8CcBtpdSbMthYAhl5Inplu2sX9QDOSylXi9hjAkaj0XsAx7ogYIvJzOtBEJyoFaC1fsfMJ20366IewJqU8lStAGb2tNaXiOgBEe3ugsgEmEMAd4QQDwEktQLyh4PBYL/v+/eY+eIEG7osec3MV4Ig+FwF2niMRlF0loieENEhl6wMsL4CuC6lfFG3dkxAHMdHhRAfikXMPKe1vktEV4lol8HmNkuyFlmRUt4C8KMIVMWt6hjNAJ5JKZcqABaSJHkK4LgNw5raj57nXRZCrJcMDLXWy0S0pJTya2cgiiLeWlAZYUdDvu2QlltYKfWH6VUJ5AJyoZVD5HDIt8Pf5/v+/fIhMomATIixQy1aa6KEJxWQ82rsUYMhT21mzFZAJiQncBPAZumUaBpyawNcCMg5t2mBphZcIaKDJu3nUkC+32qapothGH4pEhgOh/Oe5z3+FVmaXgvDcKP0/IDnedkP5DkT4vmaLgTUDnmZnO0x3JWA2iHPH8Zx3DQjjWF0LaByyJn59y+pwSlVK2IaAnICGwAWsy/MnPX6fKO9BgumKcCATvslvYD2nrmt6BNw62d7tD6B9p65rfgvEsiuyHNufXOG9l0ptaeIVvWX8mXbG6Izeg1AzPw8CIILTQIOE9EaEe2dFjGTfQB8S5JkoXxtr3yxtXWXf0REp/+C14ubzPyWmW+UyWfCG9/MmbgzyzW9gFm637fQrN3vE+gTcODAT+5+dkD56fOvAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);
export const LinearFollowersIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect y="0.75" width="32" height="32" rx="16" fill="url(#paint0_linear_470_1371)" />
    <rect x="6" y="7" width="20" height="20" fill="url(#pattern0)" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_470_1371" transform="scale(0.0208333)" />
      </pattern>
      <linearGradient id="paint0_linear_470_1371" x1="16" y1="0.75" x2="16" y2="32.75" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00D1FF" />
        <stop offset="1" stopColor="#BD00FF" />
      </linearGradient>
      <image
        id="image0_470_1371"
        width="48"
        height="48"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACP0lEQVR4nO2Yy04UQRSGKxrQxLCTqMjCNWLc6cIH0CCv4IKVzBDCa7DBQPCCujMxxrWIMYov4QV9BxOYgQ0Z9NOTFEnTzHRdeqrnZNJf0kkvuqr+v/vUOafamJqampqhArgBrAHfgAN7yf0qMG20ApwDngJ/6M0R8BgYNQrFf8GfbVUmgGeEs24UxXxR2BSF03UNBmTDxvJIg4HvJQx81WBgv4SBtgYD7RIGWoPWPxQhtFrCwIoGA9M2JYYiY6aMBmx7EMqa0YK0BcDnAPGfgBGjCWti3RFOR7bw6RKfRdoDqbCSYWyN2Lf3K2pivmZYACaBBeADsJM5wcn9FtCUZ4w2gHG7mTseGUta9VfAFaMBYFZ6IMJpATODFt+MrNrHyNhGv0Wdte3EnK0D7+11psubjzm5dTNR7ksAY8AD4A3w+9QS8DD3/ERk2PRiD7gcI/wm8NJxiJEsciE37gUOzOm1XGyECL8GvPYMgbddUmUngYEOcNVH/H37yXw5scmARZ9BJtyA0HSJXwL+Esat3BxbCQ1sFom/E5k1ThQc4JePYBc91topGuD15rpw3ufQ3ycD7RR/G0YrNNAKHeDDeG6en4MKoVhu5+aRquzExK3/LoWBxdw8CwkNNFIY+FhhIZtIYeBQeqXcXBsJDDwxVSG1IbCau9gFLlVmwJq4W/IscIwU19lKxWdMNPpwoJkfiPiMiZnIcNoF7hkNABeBZbvhXUi2eR51gEmN9PM2rKTY/cj8yZP7TQmXwlT5f5J/hWVgAqmGrucAAAAASUVORK5CYII="
      />
    </defs>
  </svg>
);
export const LinearLikesIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect y="0.5" width="32" height="32" rx="16" fill="url(#paint0_linear_470_1365)" />
    <rect x="6" y="7" width="20" height="20" fill="url(#pattern0)" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_470_1365" transform="scale(0.0208333)" />
      </pattern>
      <linearGradient id="paint0_linear_470_1365" x1="16" y1="0.5" x2="16" y2="32.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00D1FF" />
        <stop offset="1" stopColor="#BD00FF" />
      </linearGradient>
      <image
        id="image0_470_1365"
        width="48"
        height="48"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNUlEQVR4nO2YsUrDUBhG/86CUCeXgKjY2XZQnKXPUnwIn6C+gqOCUKpDJ9/AXcFHELEa93rKjxYE0yaNTXs/es+aBM4hl0A+s8gaAbSAe2DL1ACOgQ++eQA2TQXg6Jf8hCsTlndGwLaJyk84MWF5Z99CBGgCbznyfr1movLOwITlnXMTlnfaJiz/BdRNVN55shAADkvIO5chyB8AQ8rRCSGgx3L5BPpAY1EBKathCCRZQjOZ9/6KuVEPSNUDiAHEN7DeRyhVD7hVD2grBzxn/ooKBZz9kRcKSKcueiIBF5nyIgEjYE854G6qvEjAqXLAY+6KF3hAZ6Z84AHvwIZyQDdXvmSArwTL+HTuVhXgE0fV9ArJlwxo/GPYKsILsFNZwM8ziU8cC96IXoHrwkcnEolEbJWMAWL1O2s8V4quAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);
export const LinearSharedIcon = ({ className }: IconProps) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="url(#paint0_linear_470_1377)" />
    <g clipPath="url(#clip0_470_1377)">
      <path
        d="M14.3333 13.5V10.1666L8.5 16L14.3333 21.8333V18.4166C18.5 18.4166 21.4167 19.75 23.5 22.6666C22.6667 18.5 20.1667 14.3333 14.3333 13.5Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_470_1377" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0057FF" />
        <stop offset="1" stopColor="#BD00FF" />
      </linearGradient>
      <clipPath id="clip0_470_1377">
        <rect width="20" height="20" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);
export const LinearViewsIcon = ({ className }: IconProps) => (
  <svg className={className} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.75" width="31" height="31" rx="15.5" fill="url(#paint0_linear_470_1357)" />
    <rect x="0.5" y="0.75" width="31" height="31" rx="15.5" stroke="black" />
    <g clipPath="url(#clip0_470_1357)">
      <path
        d="M21.2077 12.3333L16.6243 9.68329C16.241 9.45829 15.7577 9.45829 15.3743 9.68329L10.791 12.3333C10.4077 12.5583 10.166 12.9666 10.166 13.4166V18.7083C10.166 19.1583 10.4077 19.5666 10.791 19.7916L15.3743 22.4416C15.7577 22.6666 16.241 22.6666 16.6243 22.4416L21.2077 19.7916C21.591 19.5666 21.8327 19.1583 21.8327 18.7083V13.4166C21.8327 12.9666 21.591 12.5583 21.2077 12.3333ZM11.8327 18.4666V14.6166L15.166 16.55V20.3916L11.8327 18.4666ZM15.9993 15.1083L12.666 13.175L15.9993 11.25L19.3327 13.175L15.9993 15.1083ZM16.8327 20.3916V16.55L20.166 14.6166V18.4666L16.8327 20.3916ZM11.8327 7.66663H8.91602C8.22435 7.66663 7.66602 8.22496 7.66602 8.91663V11.8333H9.33268V9.33329H11.8327V7.66663ZM20.166 7.66663H23.0827C23.7743 7.66663 24.3327 8.22496 24.3327 8.91663V11.8333H22.666V9.33329H20.166V7.66663ZM11.8327 24.3333H8.91602C8.22435 24.3333 7.66602 23.775 7.66602 23.0833V20.1666H9.33268V22.6666H11.8327V24.3333ZM20.166 24.3333H23.0827C23.7743 24.3333 24.3327 23.775 24.3327 23.0833V20.1666H22.666V22.6666H20.166V24.3333Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_470_1357" x1="16" y1="0.25" x2="16" y2="32.25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00D1FF" />
        <stop offset="1" stopColor="#BD00FF" />
      </linearGradient>
      <clipPath id="clip0_470_1357">
        <rect width="20" height="20" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

export const PlusIcon = ({ className }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path
      d="M20.7077 11.9587H11.9577V20.7087H9.04102V11.9587H0.291016V9.04199H9.04102V0.291992H11.9577V9.04199H20.7077V11.9587Z"
      fill="white"
    />
  </svg>
);

export const PausedCircle = ({ className }: IconProps) => (
  <svg className={className} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_609_1983)">
      <path
        d="M15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM13.75 20H11.25V10H13.75V20ZM18.75 20H16.25V10H18.75V20Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_609_1983">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const TrackingIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="30" height="30" fill="url(#pattern0)" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_609_1994" transform="scale(0.0208333)" />
      </pattern>
      <image
        id="image0_609_1994"
        width="48"
        height="48"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABlklEQVR4nO2YPUvDQByHT4qgk3NRQRwVQTs6iZuDm7o5ioNDRxdfKrj4MYqbi9RPIOjgJCiIg7gJpu5WUPCRgxPicbSXmJcr/T9j7pfk9+TuQohSgiAIwQHUgAsgonwi06WWpHyH8Oh4SRjbUGn5CLQJl7aPwB9UyZC0jwhkDDIDBuCW4rjJfA8AO4XVh808BMaA9wLKvwEjmQuYsVPHDeeBOnCdoOQD0AAWHWPHvn3SCCw5bjgbG5/uIvNbeiaWn7MyX8BkngJDwKMV2XJcY8IhMO7IbVuZsyR9EguY8RUrcgdUrMyhQ2DfylSA+9j4h57B3AVM5tyKNYGqfsqmvF4KNvrYgclUHfupkbZPGoEpjzfSt/my9dncz8BoYQImt9elkH7aG7HsUQ+B1f/2SSMwDFw6ynwCa468a19omln0SXWCnnbgBHgxm/AKWO6SXzefJDr7BOzaL4BCBYoAESgZBnEGIsLldSB+q9QC/rG14LvutEQrkOUUmS5+5YV+hx6o0KFfBMgYJQIJkRmwUIIgCKof+QF/Iq/SutwXigAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);

export const OptimizationIcon = ({ className }: IconProps) => (
  <svg className={className} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9833 4.14193L14.9583 5.6836C15.4526 6.66949 15.6946 7.76262 15.6626 8.86503C15.6306 9.96744 15.3256 11.0447 14.775 12.0003H3.22498C2.50929 10.7587 2.21273 9.31953 2.37926 7.89615C2.5458 6.47278 3.16657 5.14095 4.14955 4.09812C5.13252 3.05529 6.42537 2.35697 7.83643 2.10668C9.24749 1.85639 10.7016 2.06746 11.9833 2.7086L13.525 1.6836C11.9554 0.677111 10.0936 0.226101 8.23739 0.402711C6.38119 0.57932 4.63785 1.37334 3.28622 2.65777C1.9346 3.9422 1.05278 5.64282 0.781829 7.48761C0.510875 9.3324 0.86644 11.2148 1.79165 12.8336C1.93707 13.0855 2.14589 13.2949 2.39734 13.4411C2.64879 13.5873 2.93412 13.6652 3.22498 13.6669H14.7666C15.0603 13.6681 15.3491 13.5916 15.6037 13.4453C15.8584 13.299 16.0698 13.0879 16.2166 12.8336C16.9845 11.5035 17.3698 9.98716 17.3301 8.45189C17.2904 6.91663 16.8272 5.4222 15.9916 4.1336L15.9833 4.14193ZM7.82498 9.84193C7.97977 9.99689 8.16358 10.1198 8.36591 10.2037C8.56824 10.2876 8.78512 10.3307 9.00415 10.3307C9.22317 10.3307 9.44005 10.2876 9.64238 10.2037C9.84471 10.1198 10.0285 9.99689 10.1833 9.84193L14.9 2.76693L7.82498 7.4836C7.67002 7.63839 7.54709 7.8222 7.46321 8.02453C7.37934 8.22686 7.33617 8.44374 7.33617 8.66276C7.33617 8.88179 7.37934 9.09867 7.46321 9.301C7.54709 9.50333 7.67002 9.68714 7.82498 9.84193Z"
      fill="white"
    />
  </svg>
);

export const DailyLimit = ({ className }: IconProps) => (
  <svg className={className} width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.1875 7.55C23.3375 3.2375 19.55 0 15 0C11.3875 0 8.25 2.05 6.6875 5.05C2.925 5.45 0 8.6375 0 12.5C0 16.6375 3.3625 20 7.5 20H23.75C27.2 20 30 17.2 30 13.75C30 10.45 27.4375 7.775 24.1875 7.55ZM17.5 11.25V16.25H12.5V11.25H8.75L15 5L21.25 11.25H17.5Z"
      fill="white"
    />
  </svg>
);
