import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../reducers/user";
import notificationReducer from "../reducers/notification";
import candidatesReducer from "../reducers/candidates";
import companiesReducer from "../reducers/companies";
import recruitersReduce from "../reducers/recruiters";
import customUserReduce from "../reducers/customUsers";
import rolesReduce from "../reducers/role";
import permissionsReduce from "../reducers/permissions";
import routesReduce from "../reducers/routes";

const store = configureStore({
  reducer: {
    user: userReducer,
    notification: notificationReducer,
    candidates: candidatesReducer,
    companies: companiesReducer,
    recruiters: recruitersReduce,
    customUsers: customUserReduce,
    roles: rolesReduce,
    permissions: permissionsReduce,
    routes: routesReduce
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
