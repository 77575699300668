import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./utils/store";
import { HashRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import "./utils/i18n";
import theme from "./styles/theme";
import './styles/globals.scss'


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HashRouter>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </HashRouter>
);
