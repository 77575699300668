import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    myColors: {
      primary: string;
      secondary: string;
      background: string;
      text: string;
      // Add more custom colors if needed
    };
  }
  interface ThemeOptions {
    myColors?: {
      primary?: string;
      secondary?: string;
      background?: string;
      text?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#111525',
      light: '#0E90FB',
      dark: '#1369CF',
    },
    secondary: {
      main: '#161B2E',
      light: '#5F829B',
    },
  },
  typography: {
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
      color: '#fff',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
      color: '#fff',
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 400,
      color: '#fff',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
      color: '#fff',
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 400,
      color: '#fff',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#fff',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
      color: '#000',
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 500,
      color: '#000',
    },
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
});

export default theme;
