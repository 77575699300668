import enCommon from "./en/common.json";
import czCommon from "./cz/common.json";

const translations = {
  en: {
    common: enCommon,
  },
  cz: {
    common: czCommon,
  },
};

export default translations;
